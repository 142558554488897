import { Col, Collapse, Form, Input, Row, Spin, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const TechStack = ({
  setContributorsUrl,
  setFinalContentData,
  finalContentData,
}) => {
  const { Panel } = Collapse;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [gotReposData, setGotReposData] = useState(false);
  const [reposData, setReposData] = useState([]);

  const [selectedRepos, setSelectedRepos] = useState([]);

  const sortData = async (array, key) => array.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return x > y ? -1 : x < y ? 1 : 0;
  });

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
      .slice()
      .reverse()
      .find((item) => num >= item.value);
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  }

  const handleAddTechStack = (e, value) => {
    if (e.target.checked === true) {
      setSelectedRepos((oldArray) => [...oldArray, value]);
      setFinalContentData((prevState) => ({
        ...prevState,
        tech_stack_details: [...prevState.tech_stack_details, value],
      }));
    } else {
      setFinalContentData((prevState) => ({
        ...prevState,
        tech_stack_details: selectedRepos.filter((item) => item !== value),
      }));
    }
  };

  useEffect(async () => {
    const getGitHubRepos = async () => {
      try {
        // const finalURL = finalContentData?.repos_url.toString().replace("users", "orgs");
        const finalURL = finalContentData?.repos_url
          .toString()
          .replace('users', 'orgs');

        axios
          .get(`${finalURL}`)
          .then((response) => {
            console.log(response);

            sortData(response?.data, 'stargazers_count').then((data) => setContributorsUrl(data[0]?.contributors_url));

            setGotReposData(true);
            setReposData(response?.data);

            // Storing Contributor s URL for the highest stars
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 404) {
              axios
                .get(`${finalContentData?.repos_url}`)
                .then((response) => {
                  console.log(response);

                  sortData(response?.data, 'stargazers_count').then((data) => setContributorsUrl(data[0]?.contributors_url));

                  setGotReposData(true);
                  setReposData(response?.data);

                  // Storing Contributor s URL for the highest stars
                })
                .catch((errorResponse) => {
                  console.log(`error: ${errorResponse}`);
                  setGotReposData(false);
                  setReposData([]);
                });
            } else {
              setGotReposData(false);
              setReposData([]);
            }
          });
      } catch (e) {
        console.log('Error', e);
        gotReposData(false);
        setReposData([]);
      }
    };

    getGitHubRepos();

    //  const result = await sortData(reposData,stargazers_count)
    //   console.log('this is the result',)
  }, []);

  const checkedValue = (jsxItem) => {
    if (finalContentData.tech_stack_details[0]) {
      const result = finalContentData.tech_stack_details.find(
        (element) => element?.name === jsxItem?.name,
      );

      if (result) return true;
      return false;
    }
    return false;
  };

  return (
    <>
      <Title level={4}>Tech Stack Details</Title>
      <div
        style={{
          overflow: 'scroll',
          height: '450px',
          border: '2px solid #EAEAEA',
          padding: '15px',
        }}
      >
        {gotReposData === true ? (
          reposData.map((item, index) => (
            <div
              style={{
                display: 'flex',
                width: 'calc(100vw* 0.7)',
                marginTop: '15px',
              }}
              id={item?.id}
            >
              <input
                type="checkbox"
                checked={checkedValue(item)}
                style={{ marginRight: '10px' }}
                onChange={(e) => handleAddTechStack(e, item)}
                id={item?.id}
              />
              <Collapse>
                <Panel
                  header={(
                    <div style={{ display: 'flex', position: 'relative' }}>
                      <div style={{ display: 'flex', width: '500px' }}>
                        <img
                          src="https://img.icons8.com/sf-regular-filled/344/github.png"
                          style={{
                            height: '40px',
                            width: '40px',
                            marginTop: '-9px',
                          }}
                        />
                        <p
                          style={{ textDecoration: 'underline' }}
                          onClick={() => window.open(`${item?.html_url}`, '_blank')}
                        >
                          {item?.full_name}
                        </p>{' '}
                      </div>

                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          display: 'flex',
                        }}
                      >
                        <div style={{ display: 'flex', width: '100px' }}>
                          <img
                            src="https://img.icons8.com/sf-regular-filled/344/group-foreground-selected.png"
                            style={{
                              height: '34px',
                              width: '34px',
                              marginTop: '-7px',
                            }}
                          />
                          <p style={{ marginLeft: '10px' }}>
                            {nFormatter(item?.watchers_count, 1)}
                          </p>{' '}
                        </div>

                        <div
                          style={{
                            marginLeft: '20px',
                            display: 'flex',
                            width: '100px',
                          }}
                        >
                          <img
                            src="https://img.icons8.com/external-those-icons-fill-those-icons/344/external-Stars-halloween-those-icons-fill-those-icons-2.png"
                            style={{
                              height: '34px',
                              width: '34px',
                              marginTop: '-7px',
                            }}
                          />
                          <p style={{ marginLeft: '10px' }}>
                            {nFormatter(item?.forks_count, 1)}
                          </p>{' '}
                        </div>

                        <div
                          style={{
                            marginLeft: '20px',
                            display: 'flex',
                            width: '100px',
                          }}
                        >
                          <img
                            src="https://img.icons8.com/material/344/code-fork--v1.png"
                            style={{
                              height: '32px',
                              width: '32px',
                              marginTop: '-6px',
                            }}
                          />
                          <p style={{ marginLeft: '10px' }}>
                            {nFormatter(item?.forks_count, 1)}
                          </p>{' '}
                        </div>
                      </div>
                    </div>
                  )}
                  key="1"
                >
                  <Form layout="vertical" form={form}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={24}>
                        <Form.Item label="About" required>
                          <TextArea
                            placeholder="About"
                            rows={5}
                            disabled
                            value={item?.description}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={12}>
                        <Form.Item label="Link to Readme" required>
                          <Input placeholder="Link to Readme" disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Link to License page" required>
                          <Input
                            placeholder="Link to License page"
                            disabled
                            value={item?.license?.url}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={12}>
                        <Form.Item label="No. of Stars" required>
                          <Input
                            placeholder="No. of Stars"
                            disabled
                            value={item?.stargazers_count}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="No. of Forks" required>
                          <Input
                            placeholder="No. of Forks"
                            disabled
                            value={item?.forks_count}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={12}>
                        <Form.Item
                          label="Latest Release Marker and Time Stamp"
                          required
                        >
                          <Input
                            placeholder="Latest Release Marker and Time Stamp"
                            disabled
                            value={moment(item?.pushed_at).format(
                              'dddd, MMMM Do YYYY, h:mm:ss a',
                            )}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Latest Updated Date and Time"
                          required
                        >
                          <Input
                            placeholder="Latest Updated Date and Time"
                            disabled
                            value={moment(item?.updated_at).format(
                              'dddd, MMMM Do YYYY, h:mm:ss a',
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={12}>
                        <Form.Item label="Technologies Used" required>
                          <Input placeholder="Technologies Used" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Panel>
              </Collapse>
            </div>
          ))
        ) : (
          <div style={{ position: 'absolute', top: '40%', left: '55%' }}>
            <Spin size="large" tip="Fetching Tech Stack Data" />
          </div>
        )}
      </div>
    </>
  );
};

export default TechStack;
