import React, { useEffect, useState } from "react";
import { Button, message, Modal, Typography, List } from "antd";
import axios from "axios";

import classes from "./ProductInformation.module.css";
import { logoutUser } from "../../commons/Utils";

const token = localStorage.getItem("userToken");

const PublishPreviewModal = ({
  showPreviewModal,
  setShowPreviewModal,
  setStepsCount,
  stepsCount,
  finalContentData,
  showErrorModal,
  setShowErrorModal,
  errorMessages,
  dataObjectID
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const type = urlParams.get("type");



  const { Title } = Typography;

  useEffect

  const publishContent = async () => {
    try {
      let finalData = finalContentData;


      finalData.status = "Published";

      axios
        .post(`${process.env.REACT_APP_CMS_URL}content/`, finalData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          message.success("Content Posted");
          setStepsCount(stepsCount + 1);
          setShowPreviewModal(false);
        })
        .catch(function (error) {
          console.log(error);
          message.error("Something went wrong. Try Again!");
        });
    } catch (e) {
      console.log("Error", e);
      if (e?.response?.status === 401) {
        message.error('User not found')
        return logoutUser()
      }
      message.error("Something went wrong. Try Again!");
    }
  };

  const changeStatusContent = async () => {
    try {
      let finalData = finalContentData;

      finalData.status = "Published";

      axios
        .patch(
          `${process.env.REACT_APP_CMS_URL}content/${finalContentData?._id}`,
          finalData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          message.success("Content Posted");
          setStepsCount(stepsCount + 1);
          setShowPreviewModal(false);
        })
        .catch(function (error) {
          console.log(error);
          message.error("Something went wrong. Try Again");
          setStepsCount(stepsCount - 1);
        });
    } catch (e) {
      console.log("Error", e);
      if (e?.response?.status === 401) {
        message.error('User not found')
        return logoutUser()
      }

      message.error('Something went wrong.')
    }
  };

  const publishModal = () => {
    setShowPreviewModal(false);
    publishContent();
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <>
      <Modal
        visible={showPreviewModal}
        className={classes.previewModal}
        mask={false}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={type === "content" ? changeStatusContent : publishModal}>
            Publish
          </Button>,
        ]}>
        <Title level={5}>Preview Product on Atlas</Title>
        Check preview in the atlas tab before publishing. Click on the link below if not directed autonmatically
        <br/> <br/>
        <a href={`https://atlas.blr1-pp.scoutflo.com/product/${finalContentData?._id ? finalContentData?._id  : dataObjectID}?draft=true`} target="_blank">Check Preview on Atlas</a>
        {/* {JSON.stringify(finalContentData)} */}
      </Modal>

      <Modal
        visible={showErrorModal}
        className={classes.previewModal}
        mask={false}
        onCancel={handleCancelErrorModal}
        footer={[
          <Button key="back" onClick={handleCancelErrorModal} type="primary">
            Ok
          </Button>,
        ]}>
        <List
          // bordered
          header={
            <div>
              Please clear the following <b>{errorMessages?.length}</b> errors
              before publishing the product
            </div>
          }
          dataSource={errorMessages}
          renderItem={(item) => (
            <List.Item>
              <div style={{ color: "red" }}>*{item}</div>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default PublishPreviewModal;
