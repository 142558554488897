import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';

const Button = ({ icon, bgColor, color, bgHoverColor, size, text, borderRadius, width,display,onClick }) => {
  const { setIsClicked, initialState } = useStateContext();
  
  return (
    <button
      type="button"
      onClick={() => setIsClicked(initialState)}
      style={{ backgroundColor: bgColor, color, borderRadius,display,textAlign:'center'}}
      className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      <div style={{fontSize:'22px'}}>
      {icon} 
      </div>

      <div style={{marginLeft:'10px'}}> {text}</div>
  
    </button>
  );
};

export default Button;
