import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Popover,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { BiEdit, BiSave } from 'react-icons/bi';
import avatarDefault from '../assets/images/user.png';
import { logoutUser } from '../commons/Utils';
import ChangePassword from './ChangePassword';
import classes from './ProfileSettings.module.css';

const ProfileSettings = () => {
  const [usersData, setUsersData] = useState([]);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const token = localStorage.getItem('userToken');
  const role = localStorage.getItem('role');

  const { Title } = Typography;

  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [email, setEmail] = useState(localStorage.getItem('email'));

  const [avatar, setAvatar] = useState(localStorage.getItem('avatar'));

  const [initLoading, setInitLoading] = useState(true);

  const [showUserModal, setShowUserModal] = useState([]);

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const userID = localStorage.getItem('userID');

  const [openPopOver, setOpenPopOver] = useState(false);

  const [disableEditUserName, setDisableEditUserName] = useState(true);
  const [disableEditEmailID, setDisabledEditEmailID] = useState(true);

  const fileInputRef = useRef();

  // const hidePopOver = () => {
  //   setOpen(false);
  // };

  // const handleOpenChangePopOver = (newOpen) => {
  //   setOpen(f);
  // };

  const makeApiCall = async () => {
    try {
      const result = await axios
        .get(`${process.env.REACT_APP_CMS_URL}register/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setInitLoading(false);
            return res?.data?.data;
          }
        });

      return setUsersData(result);
    } catch (e) {
      setInitLoading(false);
      message.error('');
      return null;
    }
  };
  useEffect(() => {
    setInitLoading(true);
    makeApiCall();
    //  setDashboardData(data)
  }, []);

  const deleteUserAccount = () => {
    try {
      axios
        .delete(`${process.env.REACT_APP_CMS_URL}register?_id=${userID}`)
        .then((response) => {
          if (response.status === 200) {
            message.success('Account Deleted');
            logoutUser();
          }
        })
        .catch((error) => {
          console.log('Error', error);
          message.error('Something went wrong. Cannot Delete');
        });
    } catch (e) {
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something went wrong');
      return null;
    }
  };

  const deleteAdminAccount = (idToDelete) => {
    try {
      axios
        .delete(
          `${process.env.REACT_APP_CMS_URL}register?super_admin_id=${userID}&_id=${idToDelete}`,
        )
        .then((response) => {
          if (response.status === 200) {
            message.success('Account Deleted');
            makeApiCall();
          }
        })
        .catch((error) => {
          console.log('Error', error);
          message.error('Something went wrong. Cannot Delete');
        });
    } catch (e) {
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something went wrong');
      return null;
    }
  };

  const updateUserData = (url) => {
    // ? Updating User Data

    console.log('User ID', localStorage.getItem('userID'));

    const data = {
      username,
      email,
      avatar: url === 'remove' ? '' : url,
      type: 'avatar',
      user_id: localStorage.getItem('userID'),
    };

    try {
      axios
        .patch(`${process.env.REACT_APP_CMS_URL}register/?id=${userID}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          message.success('User Data Updated');
          localStorage.setItem('username', username);
          localStorage.setItem('email', email);

          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log('Error', e);

      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something we wrong');
      return null;
    }
  };

  const removeAvatarImage = () => {
    setAvatar('');
    updateUserData('remove');
    localStorage.setItem('avatar', avatarDefault);
  };
  const saveUserName = () => {
    setDisableEditUserName(true);
    updateUserData(avatar);
  };

  const saveEmailID = () => {
    setDisabledEditEmailID(true);
    updateUserData(avatar);
  };

  const onClickImageButton = async (e) => {
    document.getElementById('imageUpload').click();
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    // onSelectImageFiles(chosenFiles);

    const handleUploadFiles = async () => {
      try {
        const formData = new FormData();
        formData.append('file', chosenFiles[0]);
        formData.append('user_name', username);
        formData.append('type', 'avatar');
        formData.append('user_id', localStorage.getItem('userID'));

        axios
          .post(`${process.env.REACT_APP_CMS_URL}upload`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response?.data?.url) {
              updateUserData(response?.data?.url);
              localStorage.setItem('avatar', response?.data?.url);
              // Make API Call
            }
          });
      } catch (err) {
        console.log('Error', err);
        if (err?.response?.status === 401) {
          message.error('User not found');
          return logoutUser();
        }
        message.error('Something went wrong. File Uploading Failed');
        return null;
      }
    };

    handleUploadFiles();
  };

  return (
    <div
      className="m-2 md:m-2 mt-24 p-2 md:p-10 bg-white rounded-3xl"
      style={{ overflowX: 'hidden' }}
    >
      {/* <Header category="Page" title="COSS Products" /> */}

      <div className={classes?.heading}>
        <div className={classes?.title}>
          <Title level={3}>Profile Settings</Title>
        </div>

        <div className={classes?.deleteButton}>
          {role === 'super-admin' ? (
            <Button danger ghost onClick={() => setShowUserModal(true)}>
              Delete Admins Account
            </Button>
          ) : (
            role === 'admin' && (
              <Button
                danger
                ghost
                onClick={() => setShowDeleteAccountModal(true)}
              >
                Delete Account
              </Button>
            )
          )}
        </div>
      </div>
      <Divider />

      {showChangePassword === false ? (
        <div>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6}>
              <div className={classes?.alignCenter}>
                <Avatar src={avatar || avatarDefault} size={164} />
              </div>
            </Col>
            <Col span={3} style={{ marginTop: '50px' }}>
              <Button
                ghost
                style={{
                  color: '#334BD0',
                  background: '#EAECFA',
                  borderRadius: '4px',
                  border: '1px solid #334BD0',
                }}
                onClick={() => fileInputRef.current.click()}
              >
                <input
                  id="imageUpload"
                  type="file"
                  onChange={(e) => onClickImageButton(e)}
                  ref={fileInputRef}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  style={{ display: 'none' }}
                />
                + Upload Image
              </Button>
              <br />
              <Button
                danger
                ghost
                style={{ marginTop: '10px' }}
                onClick={() => removeAvatarImage()}
              >
                Remove Image
              </Button>
            </Col>

            {/*
      <Button>Bye</Button> */}
          </Row>
          <br />
          <Form layout="vertical">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item label="Name" required id="1">
                  <div style={{ display: 'flex' }}>
                    <Input
                      placeholder="Name"
                      value={username}
                      disabled={disableEditUserName}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <div
                      style={{
                        marginLeft: '10px',
                        marginTop: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      {disableEditUserName ? (
                        <BiEdit onClick={() => setDisableEditUserName(false)} />
                      ) : (
                        <BiSave onClick={() => saveUserName()} />
                      )}
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item label="Email ID" required id="1">
                  <div style={{ display: 'flex' }}>
                    <Input
                      placeholder="Email ID"
                      value={email}
                      disabled={disableEditEmailID}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div
                      style={{
                        marginLeft: '10px',
                        marginTop: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      {disableEditEmailID ? (
                        <BiEdit onClick={() => setDisabledEditEmailID(false)} />
                      ) : (
                        <BiSave onClick={() => saveEmailID()} />
                      )}
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item label="Password" required id="1">
                <Input.Password
                  placeholder="Password"
                  // value={gitHubData?.name}
                  // disabled
                />
              </Form.Item>
            </Col>
  </Row> */}
          </Form>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} style={{ display: 'flex' }}>
              <div
                className={classes?.deleteButton}
                style={{
                  color: 'gray',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => setShowChangePassword(true)}
              >
                Change Password
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <ChangePassword setShowChangePassword={setShowChangePassword} />
      )}
      {showUserModal === true && (
        <Modal
          title="Registered Users"
          centered
          style={{ width: '620px' }}
          visible={showUserModal}
          footer={false}
          // onOk={() => setModal2Open(false)}
          onCancel={() => setShowUserModal(false)}
        >
          <List
            className="demo-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            dataSource={usersData}
            renderItem={(item) => (
              <List.Item
                actions={[
                  item?.role !== 'super-admin' && (
                    <Popover
                      content={(
                        <a
                          key="list-loadmore-edit"
                          style={{ color: 'red' }}
                          onClick={() => deleteAdminAccount(item?._id)}
                        >
                          Permanently Delete {item?.username}
                        </a>
                      )}
                      title={`Delete ${item?.username}?`}
                      trigger="click"
                    >
                      {/* <p>Are your sure you want to delete {item?.username} account?</p> */}
                      <a style={{ color: 'red' }}>Delete</a>
                    </Popover>
                  ),
                ]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={<Avatar src={avatar} />}
                    title={<a href="https://ant.design">{item?.username}</a>}
                    description={(
                      <>
                        {item?.role}
                        <br /> {item.email}
                      </>
                    )}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Modal>
      )}

      {showDeleteAccountModal === true && (
        <Modal
          title="Registered Users"
          centered
          visible={showDeleteAccountModal}
          onOk={() => deleteUserAccount()}
          onCancel={() => setShowDeleteAccountModal(false)}
        >
          <p>This will delete your account permanently from Scoutflo CMS.</p>
        </Modal>
      )}
    </div>
  );
};
export default ProfileSettings;
