import React, { useEffect, useState } from 'react';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleFilled,
  ScheduleTwoTone
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Input,
  List,
  message,
  Modal,
  Popover,
  Result,
  Row,
  Steps,
  Typography,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useWindowDimensions from '../../commons/useWindowDimensions';
import { logoutUser } from '../../commons/Utils';
import { isFinalDataValid } from '../../commons/Validations';
import classes from './AddProduct.module.css';
import Community from './Community';
import ProductInformation from './ProductInformation';
import PublishPreviewModal from './PublishPreviewModal';
import Success from './Success';
import TechStack from './TechStack';

const AddProduct = () => {
  const [contributorsUrl, setContributorsUrl] = useState();

  const [stepsCount, setStepsCount] = useState(1);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();
  const { Title } = Typography;
  const { width } = useWindowDimensions();

  const [urlValidated, setUrlValidated] = useState(false);
  const [gitHubData, setGitHubData] = useState([]);

  const [showClearDraftModal, setShowClearDraftModal] = useState(false);

  const [searchName, setSearchName] = useState('');

  const [dataObjectID, setDataObjectID] = useState('');

  const [lastSavedDraftTime, setLastSavedDraftTime] = useState();
  const [lastSavedMessage, setLastSavedMessage] = useState('');


  const [windowOnFocus, setWindowOnFocus] = useState();

  const [searchParams, setSearchParams] = useSearchParams();


  const [errorMessages, setErrorMessages] = useState([]);

  const addNewProduct = () => {
    localStorage.removeItem('objectIDForAddProduct');
    setSearchName('');
    setUrlValidated(false);
    setDataObjectID('');
    setGitHubData([]);
  };

  const [showHover, setShowHover] = useState(false);
  const currentTime = moment();

  useEffect(() => {
    if (urlValidated) {
      const result = moment.duration(currentTime.diff(lastSavedDraftTime));
      setLastSavedMessage(result.humanize());
    }
  }, [lastSavedDraftTime, windowOnFocus]);

  const [finalContentData, setFinalContentData] = useState({
    name: false,
    github_url: '',
    date_created: '',
    created_by: '',
    repos_url: '',

    // Owner Data
    owner_email_id: localStorage.getItem('email'),
    owner_role: localStorage.getItem('role'),
    owner_user_id: localStorage.getItem('userID'),
    owner_username: localStorage.getItem('username'),
    owner_avatar_url: localStorage.getItem('avatar')?.includes('data')
      ? ''
      : localStorage.getItem('avatar'),

    // All the unRequired fields
    category: '',
    avatar_url: '',
    about_us: '',
    sub_category: '',
    status: 'Unpublished',
    product_website: '',
    product_roadmap: '',
    founding_team: [],
    proprietary_alternatives: [],
    description: '',
    contact_email_id: '',
    images: [],
    documents: [],
    tech_stack_details: [],
    youtube_video_urls: [],

    top_contributors: [],
    total_members: '',
    active_members: '',

    categories: [],

    pricing_details: [
      {
        value: 'Self-Hosted',
        checked: false,
        tier: [
          {
            heading: '',
            description: '',
            currency: '',
            rate_monthly: '',
            rate_annualy: '',
            pricing_value: '',
            pricing_unit: '',
            pricing_unit_two: '',
            display_toggle: 'month',
            cycle: [
              {
                value: 'Monthly',
                checked: 'false',
              },
              {
                value: 'Annual',
                checked: 'false',
              },
              {
                value: 'Monthly & Annual',
                checked: 'false',
              },
            ],
          },
        ],
      },
      {
        value: 'Cloud-Hosted',
        checked: false,
        tier: [
          {
            heading: '',
            description: '',
            currency: '',
            rate_monthly: '',
            rate_annualy: '',
            pricing_value: '',
            pricing_unit: '',
            pricing_unit_two: '',
            display_toggle: 'month',
            cycle: [
              {
                value: 'Monthly',
                checked: 'false',
              },
              {
                value: 'Annual',
                checked: 'false',
              },
              {
                value: 'Monthly & Annual',
                checked: 'false',
              },
            ],
          },
        ],
      },
    ],
    social_media_urls: {
      twitter_url: '',
      linkedin_url: '',
      product_hunt_url: '',
      discord_url: '',
      slack_url: '',
      crunchbase_url: '',
      instagram_url: '',
      gitter_url: '',
      other_url: '',
      hashtags: '',
    },

    deploy_url: {
      heroku: '',
      digital_ocean: '',
      vercel: '',
      railway: '',
    },
  });

  const token = localStorage.getItem('userToken');

  const concatGitHubUrl = async (url) => {
    const urlToConcat = url.toString();
    const githubApiUrl = 'https://api.github.com/users/';

    const delimiter = '/';
    const start = 3;
    const tokens = urlToConcat && urlToConcat?.split(delimiter).slice(start);
    const trimmedString = tokens.join(delimiter);
    const result = githubApiUrl.concat(trimmedString);

    let finalURl;
    if (result.slice(-1) === '/') {
      finalURl = result.slice(0, -1);
    } else {
      finalURl = result;
    }

    return finalURl;
  };

  const getGithubRepo = async (url, editFeature) => {
    const finalUrl = await concatGitHubUrl(url);

    try {
      axios
        .get(`${finalUrl}`)
        .then((response) => {
          setLastSavedDraftTime(moment());
          setGitHubData(response?.data);
          if (!editFeature) {
            draftsInitialCall(response?.data);
            setFinalContentData((prevState) => ({
              ...prevState,
              name: response?.data?.name ? response?.data?.name : 'NA',
              repos_url: response?.data?.repos_url ? response?.data?.repos_url : 'NA',
              about_us: response?.data?.bio ? response?.data?.bio : 'NA',
              github_url: response?.data?.html_url ? response?.data?.html_url : 'NA',

              owner_email_id: localStorage.getItem('email'),
              owner_role: localStorage.getItem('role'),
              owner_user_id: localStorage.getItem('userID'),
              owner_username: localStorage.getItem('username'),
              owner_avatar_url: localStorage.getItem('avatar')?.includes('data')
                ? ''
                : localStorage.getItem('avatar'),

              date_created: moment(),
              // product_website: !!response?.data?.blog
              //   ? response?.data?.blog
              //   : "NA",
              // contact_email_id: !!response?.data?.email
              //   ? response?.data?.email
              //   : "",
              avatar_url: response?.data?.avatar_url ? response?.data?.avatar_url : 'NA',
            }));
          } else {
            message.success('Draft Details fetched');
          }
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 401) {
            message.error('Something went wrong');
            // return logoutUser();
          }

          setUrlValidated(false);
          message.error('Please check the URL and try again.');

          setGitHubData([]);
        });
    } catch (e) {
      console.log('Error', e);
      setUrlValidated(false);
      setGitHubData([]);
    }
  };

  const draftsInitialCall = async (data) => {
    try {
      const dataToSend = {
        name: data?.name,
        github_url: data?.html_url,
        status: 'Unpublished',
        repos_url: data?.repos_url,
        avatar_url: data?.avatar_url,

        owner_email_id: localStorage.getItem('email'),
        owner_role: localStorage.getItem('role'),
        owner_user_id: localStorage.getItem('userID'),
        owner_username: localStorage.getItem('username'),
        owner_avatar_url: localStorage.getItem('avatar')?.includes('data')
          ? ''
          : localStorage.getItem('avatar'),

        pricing_details: [
          {
            value: 'Self-Hosted',
            checked: false,
            tier: [
              {
                heading: '',
                description: '',
                currency: '',
                rate_monthly: '',
                rate_annualy: '',
                pricing_value: '',
                pricing_unit: '',
                pricing_unit_two: '',

                display_toggle: 'month',
                cycle: [
                  {
                    value: 'Monthly',
                    checked: 'false',
                  },
                  {
                    value: 'Annual',
                    checked: 'false',
                  },
                  {
                    value: 'Monthly & Annual',
                    checked: 'false',
                  },
                ],
              },
            ],
          },
          {
            value: 'Cloud-Hosted',
            checked: false,
            tier: [
              {
                heading: '',
                description: '',
                currency: '',
                rate_monthly: '',
                rate_annualy: '',
                pricing_value: '',
                pricing_unit: '',
                pricing_unit_two: '',
                display_toggle: 'month',
                cycle: [
                  {
                    value: 'Monthly',
                    checked: 'false',
                  },
                  {
                    value: 'Annual',
                    checked: 'false',
                  },
                  {
                    value: 'Monthly & Annual',
                    checked: 'false',
                  },
                ],
              },
            ],
          },
        ],
        date_created: moment().format('YYYY-MM-DD'),
        contact_email_id: data?.email ? data?.email : 'NA',
        about_us: data?.bio ? data?.bio : 'NA',
        description: '',
        social_media_urls: {
          twitter_url: data?.twitter_username
            ? `https://www.twitter.com/${data?.twitter_username}`
            : '',
        },
      };
      axios
        .post(`${process.env.REACT_APP_CMS_URL}drafts`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setDataObjectID(response?.data?.id);
          setUrlValidated(true);
          message.success('Url Validated');
          localStorage.setItem('objectIDForAddProduct', response?.data?.id);
        })
        .catch((error) => {
          console.log('Error while posting to drafts', error);

          if (error?.response?.status === 401) {
            message.error('User not found');
            return logoutUser();
          }
          setSearchName('');

          if (error.response.data.message) {
            message.error(`${error.response.data.message}`);
          } else {
            message.error('Something went wrong. Try Again!');
          }
        });
    } catch (e) {
      console.log('Error', e);

      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      setSearchName('');
      message.error('Something went wrong. Try Again!');
    }
  };

  const draftPostFromContent = async (data) => {
    delete data?._id;
    try {
      axios
        .post(`${process.env.REACT_APP_CMS_URL}drafts`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          message.success('Drafts Saved');
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.message) {
            message.error(`${error.response.data.message}`);
          } else {
            message.error('Something went wrong. Try Again!');
          }
        });
    } catch (e) {
      console.log('Error', e);

      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }

      setSearchName('');
      message.error('Something went wrong. Try Again!');
    }
  };

  const deleteContent = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_CMS_URL}content/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.log('Error is', e);
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }

      message.error('Something went wrong. Try Again!');
      return null;
    }
  };

  const getContentApi = async (id) => {
    const result = await axios
      .get(`${process.env.REACT_APP_CMS_URL}content/?_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setFinalContentData(res?.data?.data[0]);
        setUrlValidated(true);
        await deleteContent(searchParams.get('id'));
        await draftPostFromContent(res?.data?.data[0]);
        navigate(`/add?id=${searchParams.get('id')}`);
      })
      .catch(e);
    {
      console.log('Some Error Occurred', e);

      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }

      message.error('Something went wrong. Try Again');
    }
  };

  const saveDraft = async () => {
    delete finalContentData?._id;
    axios
      .patch(`${process.env.REACT_APP_CMS_URL}drafts/${dataObjectID}`, finalContentData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        message.success('Draft saved.');
        setLastSavedDraftTime(moment());
      })
      .catch((error) => {
        console.log(error);
        message.error('Something went wrong. Try Again!');
      });
  };

  const deleteDraft = async () => {
    try {
      await axios
        .delete(`${process.env.REACT_APP_CMS_URL}drafts/${dataObjectID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            message.success('Draft Deleted');
            localStorage.removeItem('objectIDForAddProduct');
            setShowClearDraftModal(false);
            setGitHubData([]);
            setStepsCount(1);
            setUrlValidated(false);
            setSearchName();
          }
        });
    } catch (e) {
      console.log('Error is', e);
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something went wrong. Try Again!', e);

      return null;
    }
  };

  const getDraft = async (id) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_CMS_URL}drafts/?id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setUrlValidated(true);
            // getGithubRepo(res?.data?.data[0]?.github_url, true);
            setSearchName(res?.data?.data[0]?.github_url);
            setFinalContentData(res?.data?.data[0]);
          }
        });
    } catch (e) {
      console.log('Some Error Occurred', e);

      if (e?.response?.status === 401) {
        message.error('Something went wrong');
        // return logoutUser();
      }
      return null;
    }
  };

  useEffect(async () => {
    if (searchParams.get('id')) {
      setDataObjectID(searchParams.get('id'));
      localStorage.setItem('objectIDForAddProduct', searchParams.get('id'));
      if (searchParams.get('type')) {
        await getContentApi(searchParams.get('id'));
        await saveDraft();
      } else {
        getDraft(searchParams.get('id'));
      }
    } else if (localStorage.getItem('objectIDForAddProduct')) {
      setDataObjectID(localStorage.getItem('objectIDForAddProduct'));
      getDraft(localStorage.getItem('objectIDForAddProduct'));
    }
  }, []);

  const nextStep = async () => {
    const tempErrorMessages = await isFinalDataValid(finalContentData, stepsCount);
    if (tempErrorMessages.length > 0) {
      setShowErrorModal(true);
      // setStepsCount(stepsCount + 1);
      setErrorMessages(tempErrorMessages);
    } else {
      if (stepsCount === 4) {
        window.open(
          `https://atlas.blr1-pp.scoutflo.com/product/${
            finalContentData?._id ? finalContentData?._id : dataObjectID
          }?draft=true`,
          '_blank',
        );
        return setShowPreviewModal(true);
      }
      setStepsCount(stepsCount + 1);
      setErrorMessages([]);
    }
  };

  const prevStep = async () => {
    setStepsCount(stepsCount - 1);
  };

  const editing = { allowDeleting: false, allowEditing: false };
  const { Step } = Steps;

  return (
    <div
      className="m-2 md:m-2 mt-24 p-2 md:p-10 bg-white rounded-3xl"
      style={{
        overflowX: 'hidden',
        width: '-webkit-fill-available',
      }}
    >
      {/* <Header category="Page" title="COSS Products" /> */}

      {/* Desktop  */}
      {width > 768 ? (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={4} className={classes.column}>
            <Title level={3}>Add Product</Title>
            {errorMessages.length > 0 ? (
              <Popover
                content={(
                  <List
                    dataSource={errorMessages}
                    renderItem={(item) => (
                      <List.Item>
                        <div style={{ color: 'red' }}>*{item}</div>
                      </List.Item>
                    )}
                  />
                )}
                title={`${errorMessages?.length} errors in form`}
                trigger="hover"
              >
                <Button type="danger" style={{ borderRadius: '10px' }}>
                  Form Errors
                </Button>
              </Popover>
            ) : null}
          </Col>
          <Col span={8} className={classes.column}>
            {urlValidated && (
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="Enter URL"
                  allowClear
                  style={{
                    marginLeft: '50px',
                    width: '250px',
                    marginRight: '10px',
                    height: '33px',
                  }}
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  disabled={urlValidated === true}
                />
                <Button
                  style={{
                    color: 'green',
                    background: '#E3F5E6',
                    borderRadius: '4px',
                    border: '1px solid #32BE5A',
                  }}
                  onClick={() => getGithubRepo(searchName)}
                  ghost
                  disabled={urlValidated === true}
                >
                  {urlValidated === true ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    'Validate'
                  )}
                </Button>
              </div>
            )}
          </Col>

          <Col span={9}>
            {urlValidated && (
              <Steps
                progressDot
                // current={stepsCount + 1}
                current={stepsCount === 2 ? 0 : stepsCount === 0 ? stepsCount - 1 : stepsCount - 2}
                style={{ width: '200px' }}
                size="small"
              >
                <Step
                  title="Product"
                  onClick={() => setStepsCount(1)}
                  disabled={!urlValidated}
                  style={{ cursor: 'pointer' }}
                />
                <Step
                  title="Tech Stack"
                  onClick={() => setStepsCount(3)}
                  disabled={!urlValidated}
                  style={{ cursor: 'pointer' }}
                />
                <Step
                  title="Community"
                  onClick={() => setStepsCount(4)}
                  disabled={!urlValidated}
                  style={{ cursor: 'pointer' }}
                />
                <Step title="Completed" />
              </Steps>
            )}
          </Col>

          {/* Tablet */}
        </Row>
      ) : (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8} className={classes.column}>
              <Title level={3}>Add Product</Title>
            </Col>
            <Col span={16} className={classes.column}>
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="Enter URL"
                  allowClear
                  style={{
                    marginLeft: '50px',
                    width: '250px',
                    marginRight: '10px',
                    height: '33px',
                  }}
                  onChange={(e) => setSearchName(e.target.value)}
                />
                <Button
                  style={{
                    color: 'green',
                    background: '#E3F5E6',
                    borderRadius: '4px',
                    border: '1px solid #32BE5A',
                  }}
                  onClick={() => getGithubRepo(searchName)}
                  ghost
                >
                  {urlValidated === true ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    'Validate'
                  )}
                </Button>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '50px', marginLeft: '-10px' }}>
            <Col span={24}>
              <Steps
                progressDot
                current={stepsCount === 2 ? 0 : stepsCount === 0 ? stepsCount - 1 : stepsCount - 2}
                style={{ width: '100px' }}
                size="small"
              >
                <Step title="Product" />
                <Step title="Tech Stack" />
                <Step title="Community" />
                <Step title="Completed" />
              </Steps>
            </Col>
          </Row>
        </>
      )}

      <Divider />

      {urlValidated !== true ? (
        <Result
          icon={<ScheduleTwoTone twoToneColor="#52c41a" />}
          title="Enter the Github Organisation URL"
          extra={(
            <>
              <Input
                placeholder="Enter URL"
                allowClear
                style={{
                  marginLeft: '50px',
                  width: '250px',
                  marginRight: '10px',
                  height: '33px',
                }}
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                disabled={urlValidated === true}
              />
              <Button
                style={{
                  color: 'green',
                  background: '#E3F5E6',
                  borderRadius: '4px',
                  border: '1px solid #32BE5A',
                }}
                onClick={() => getGithubRepo(searchName)}
                ghost
                disabled={urlValidated === true}
              >
                Validate{' '}
              </Button>
            </>
          )}
        />
      ) : (
        <>
          {(stepsCount === 1 || stepsCount === 2) && finalContentData?.github_url && (
            <ProductInformation
              stepsCount={stepsCount}
              saveDraft={saveDraft}
              setStepsCount={setStepsCount}
              gitHubData={gitHubData}
              finalContentData={finalContentData}
              setFinalContentData={setFinalContentData}
            />
          )}
          {stepsCount === 3 && (
            <TechStack
              stepsCount={stepsCount}
              setStepsCount={setStepsCount}
              gitHubData={gitHubData}
              setContributorsUrl={setContributorsUrl}
              finalContentData={finalContentData}
              setFinalContentData={setFinalContentData}
            />
          )}

          {stepsCount === 4 && (
            <Community
              contributorsUrl={contributorsUrl}
              setFinalContentData={setFinalContentData}
              finalContentData={finalContentData}
            />
          )}

          {stepsCount === 5 && (
            <Success finalContentData={finalContentData} dataObjectID={dataObjectID} />
          )}
        </>
      )}

      <Divider />

      {width > 820 && stepsCount !== 5 ? (
        <>
          {stepsCount !== 6 && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                {urlValidated && (
                  <Button type="primary" onClick={() => addNewProduct()}>
                    Add New
                  </Button>
                )}

                {urlValidated && (
                  <Button
                    danger
                    ghost
                    onClick={() => setShowClearDraftModal(true)}
                    style={{ marginLeft: '10px' }}
                  >
                    Clear Draft
                  </Button>
                )}
                {showHover === true && urlValidated && (
                  <Popover
                    content={(
                      <>
                        Hey Rachit! You last saved this draft a while ago
                        <br /> Always Remember to save your drafts to avoid losing your changes.
                      </>
                    )}
                    style={{ marginLeft: '10px' }}
                    title={(
                      <div style={{ position: 'relative' }}>
                        <InfoCircleFilled style={{ marginRight: '10px', color: '#F29C00' }} />
                        Save Draft?{' '}
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                          {' '}
                          <a onClick={() => setShowHover(false)}>
                            <CloseCircleTwoTone />
                          </a>
                        </div>
                      </div>
                    )}
                    trigger="click"
                    visible={false}
                    // onVisibleChange={handleVisibleChange}
                  >
                    <Button
                      style={{
                        color: 'green',
                        background: '#E3F5E6',
                        borderRadius: '4px',
                        border: '1px solid #32BE5A',
                        marginLeft: '10px',
                      }}
                      onClick={() => saveDraft()}
                      ghost
                    >
                      Save Draft
                    </Button>{' '}
                  </Popover>
                )}

                {urlValidated && showHover !== true && (
                  <Button
                    style={{
                      color: 'green',
                      background: '#E3F5E6',
                      borderRadius: '4px',
                      border: '1px solid #32BE5A',
                      marginLeft: '10px',
                    }}
                    onClick={() => saveDraft()}
                    ghost
                  >
                    Save Draft
                  </Button>
                )}

                {urlValidated && lastSavedMessage !== '' && (
                  <p
                    style={{
                      color: 'gray',
                      textDecoration: 'underline',
                      marginTop: '5px',
                      marginLeft: '10px',
                    }}
                  >
                    <InfoCircleFilled style={{ color: '#F29C00', marginRight: '10px' }} />
                    Last Saved {lastSavedMessage} Ago
                  </p>
                )}
              </div>

              <div style={{ display: 'flex' }}>
                {urlValidated && (
                  <>
                    {stepsCount !== 1 && (
                      <Button type="secondary" onClick={prevStep} style={{ marginLeft: '10px' }}>
                        Back
                      </Button>
                    )}

                    <Button type="primary" style={{ marginLeft: '10px' }} onClick={nextStep}>
                      {stepsCount === 4 ? 'Publish' : 'Next'}
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : width <= 820 && stepsCount !== 5 ? (
        <Row gutter={{ xs: 8, sm: 32, md: 32, lg: 32 }}>
          <Col span={13} />
          {stepsCount !== 6 && (
            <Col span={9} style={{ display: 'flex' }}>
              {urlValidated && (
                <Button danger ghost onClick={() => setShowClearDraftModal(true)}>
                  Clear Draft
                </Button>
              )}
              {urlValidated && stepsCount !== 1 && (
                <Button type="secondary" onClick={prevStep} style={{ marginLeft: '10px' }}>
                  Back
                </Button>
              )}
              <Button type="primary" style={{ marginLeft: '10px' }} onClick={nextStep}>
                {stepsCount === 4 ? 'Publish' : 'Next'}
              </Button>
            </Col>
          )}
        </Row>
      ) : null}

      <br />
      {(showPreviewModal === true || showErrorModal === true) && (
        <PublishPreviewModal
          showPreviewModal={showPreviewModal}
          setShowPreviewModal={setShowPreviewModal}
          setStepsCount={setStepsCount}
          stepsCount={stepsCount}
          finalContentData={finalContentData}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          errorMessages={errorMessages}
          dataObjectID={dataObjectID}
        />
      )}

      {showClearDraftModal === true && (
        <Modal
          style={{ top: 250 }}
          visible={showClearDraftModal}
          mask={false}
          onCancel={() => setShowClearDraftModal(false)}
          footer={[
            <Button key="back" onClick={() => setShowClearDraftModal(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{ background: 'red' }}
              onClick={() => deleteDraft()}
            >
              Delete
            </Button>,
          ]}
        >
          <Title level={5}>Are you sure you want to delete the draft?</Title>
        </Modal>
      )}
    </div>
  );
};
export default AddProduct;
