import React from "react";
import { MdOutlineCancel } from "react-icons/md";

import { Button } from ".";
import { userProfileData } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import avatarDefault from "../assets/images/user.png";
import { Link } from "react-router-dom";
import { logoutUser } from "../commons/Utils";

const UserProfile = () => {
  const { currentColor } = useStateContext();

  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const avatar = localStorage.getItem("avatar");

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar ? avatar : avatarDefault}
          alt="user-profile"
        />
        <div>
          <div className="font-semibold text-xl dark:text-gray-200">
            {" "}
            {username}{" "}
          </div>
          <div className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            Administrator{" "}
          </div>
          <div className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            {email}{" "}
          </div>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
          >

            <Button
              icon={item.icon}
              color="rgb(153, 171, 180)"
              bgHoverColor="light-gray"
              size="2xl"
              borderRadius="50%"
            />

            <Link
              to={{
                pathname: `/profile`,
              }}
            >
              <div>
                <p className="font-semibold dark:text-gray-200 ">
                  {item.title}
                </p>
                <p className="text-gray-500 text-sm dark:text-gray-400">
                  {" "}
                  {item.desc}{" "}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <button
          type="button"
          onClick={() => logoutUser()}
          style={{
            backgroundColor: currentColor,
            color: "white",
            borderRadius: "10px",
            textAlign: "center",
          }}
          className={`p-3 w-full hover:drop-shadow-xl hover:bg-${currentColor}`}
        >
          <div style={{ marginLeft: "10px" }}> Logout</div>
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
