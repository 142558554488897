import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ScoutfloLogo from "../assets/images/scoutflo-logo.svg";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel, MdAdd } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Button } from ".";


import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import AddProduct from "../pages/AddProduct/AddProduct";

const Sidebar = () => {

  let navigate = useNavigate();
  const [stopNavigation, setStopNavigation] = React.useState(false)

  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  // const [showAlertPopUp,setShowAlertPopUp] = React.useState(localStorage.getItem('objectIDForAddProduct') ? true : false)  

  const handleCloseSideBar = () => {

    const setMenu = () => {
      if (activeMenu !== undefined && screenSize <= 900) {
        setActiveMenu(false);
      }
    }


    const checkIfPopUp = () => {
      let showAlertPopUp = localStorage.getItem('objectIDForAddProduct') ? true : false
      if (showAlertPopUp) {
        console.log('I am here')

        if (confirm("Are you sure you want to leave this page?")) {
          localStorage.removeItem('objectIDForAddProduct')
          e.preventDefault()
          showAlertPopUp = false
          setStopNavigation(false)
          setMenu()
        }
        else {
          setStopNavigation(true)
        }
      }

      else {
        navigate('/add')
      }
    }

    checkIfPopUp()

  }



  const handleNavigateAdd = () => {
    localStorage.removeItem('objectIDForAddProduct')
    window.open('/add', '_self')
  }


  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/Dashboard"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img
                src={ScoutfloLogo}
                alt="Scoutflo logo"
                width={150}
                height={200}
              />{" "}
              <span></span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>

          <div className="mt-10" >

            <p className="m-3 mt-4 uppercase" style={{ display: 'flex', backgroundColor: currentColor, cursor: 'pointer' }} onClick={() => handleNavigateAdd()}>
              <div ><Button
                color="white"
                bgColor={currentColor}
                text="Add Product"
                icon={<MdAdd />}
                display="flex"
                borderRadius="10px"
              /></div>
            </p>

            {links.map((item) => (
              <div key={item.title}>



                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {/* {item.title} */}
                </p>

                {item.links.map((link) => (
                  <NavLink
                    to={link.name === 'Add a Product' ? `/add` : `/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      color: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                    <span className="capitalize ">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
