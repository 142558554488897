import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import React from "react";
import avatar from "../assets/images/scoutflo-logo.svg";
import avatarDefault from "../assets/images/user.png";
import "./Login.css";

export const Login = ({ setUserLoggedIn }) => {
  const onFinish = (values) => {
    try {
      axios
        .post(`${process.env.REACT_APP_CMS_URL}login`, values)
        .then(function (response) {
          localStorage.setItem("userToken", response?.data?.user);
          localStorage.setItem("username", response?.data?.username);
          localStorage.setItem("email", response?.data?.email);
          localStorage.setItem("role", response?.data?.role);
          localStorage.setItem("userID", response?.data?.userID);
          localStorage.setItem(
            "avatar",
            response?.data?.avatar ? response?.data?.avatar : avatarDefault
          );
          setUserLoggedIn(true);
        })
        .catch(function (error) {
          message.error("Invalid Username or Password");
        });
    } catch (e) {
      message.error("Invalid Username or Password");
    }
  };

  return (
    <div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <img
          className="rounded-full w-8 h-8"
          src={avatar}
          alt="user-profile"
          style={{ height: "40px", width: "100%", marginBottom: "20px" }}
        />
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            className="loginInput"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            autoComplete="new-password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            className="loginInput"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
