import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Divider,
  Typography,
  Image,
  Row,
  Col,
  Button,
  Form,
  Input,
  message
} from "antd";
import classes from "./ProfileSettings.module.css";
import { logoutUser } from "../commons/Utils";

const ChangePassword = ({ setShowChangePassword }) => {

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const [errorType, setShowErrorType] = useState('')

  let token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluMkBhZG1pbi5jb20iLCJpYXQiOjE2NTc5NzQ1NjR9.3KQY7NTP8VCP5NayRIqTUQBDksfeXf8bvGilQunkSPk";

  const { Title } = Typography;

  const makeApiCall = async () => {

    if (oldPassword == '') {
      message.error('Please Enter Your Current Password')
      return setShowErrorType('old')

    }

    if (newPassword == '') {
      message.error('Please Enter Your New Password')
      return setShowErrorType('new')
    }

    if (confirmNewPassword == '') {
      message.error('Please Confirm Your New Password')
      return setShowErrorType('confirm')
    }

    if (confirmNewPassword !== newPassword) {
      message.error('New Password and Re-Entered Password does not match')
      return setShowErrorType('nomatch')
    }

    try {
      axios
        .patch(
          `${process.env.REACT_APP_CMS_URL}login/`,
          {
            email: localStorage.getItem('email'),
            new_password: newPassword,
            old_password: oldPassword
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          message.success('Password Changed')
          setShowChangePassword(false)

        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            message.error('User not found')
            return logoutUser()
          }

          if (error?.response?.data?.error) {
            message.error(`${error?.response?.data?.error}`)
          }
          else { message.error('Something went wrong') }
        });
    } catch (e) {
      console.log("Some Error Occurred", e);
      message.error('Something went wrong')
      return null;
    }
  };

  return (
    <Form layout="vertical">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Enter Existing Password" required id="1">
            <Input.Password
              placeholder="Existing Password"
              onChange={(e) => setOldPassword(e.target.value)}
              status={errorType === 'old' && 'error'}
            // value={gitHubData?.name}
            // disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Enter New Password" required id="1">
            <Input.Password
              placeholder="Enter New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              status={(errorType === 'new' || errorType === 'nomatch') && 'error'}

            // value={gitHubData?.name}
            // disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Re-enter New Password" required id="1">
            <Input.Password
              status={(errorType === 'confirm' || errorType === 'nomatch') && 'error'}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Password"
            // value={gitHubData?.name}
            // disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} style={{ display: "flex" }}>
          <div className={classes?.deleteButton}>
            <Button style={{ marginRight: '10px' }} onClick={() => setShowChangePassword(false)}>Back</Button>

            <Button
              ghost
              style={{
                color: "green",
                background: "#E3F5E6",
                borderRadius: "4px",
                border: "1px solid #32BE5A",
              }}

              onClick={() => makeApiCall()}
            >
              Change Password
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default ChangePassword;
