import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  List,
  message,
  Modal,
  Select,
  Tag,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

// import { Formik, Field, Form, ErrorMessage } from "formik";
import { Col, Row } from 'antd';
import moment from 'moment';
import { PricingDetails } from './PricingDetails';
import classes from './ProductInformation.module.css';

import { logoutUser } from '../../commons/Utils';
import { isYouTubeURL } from '../../commons/Validations';

const ProductInformation = ({ stepsCount, saveDraft, finalContentData, setFinalContentData }) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const token = localStorage.getItem('userToken');

  const fileInputRef = useRef();
  const documentInputRef = useRef();

  const [uploadedImageFiles, setUploadedImageFiles] = useState([]);
  const [uploadedDocumentFiles, setUploadedDocumentFiles] = useState([]);

  const [uploadedFoundingMember, setUploadedFoundingMember] = useState([]);

  const [imageName, setImageName] = useState('');
  const [documentName, setDocumentName] = useState('');

  const [founderName, setFounderName] = useState('');
  const [founderUrl, setFounderUrl] = useState('');

  const [categoryChosen, setCategoryChosen] = useState('');
  const [subCategoryChosen, setSubCategoryChosen] = useState([]);

  const [metaTagValue, setMetaTagValue] = useState('');

  const [categoires, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [openImageModal, setOpenImagepModal] = useState(false);

  const [openDocsModal, setOpenDocsModal] = useState(false);

  const [openFoundingTeamModal, setOpenFoundingTeamModal] = useState(false);

  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const is_super_admin = localStorage.getItem('role') === 'super-admin';

  const [propAlternativesValue, setPropAlternativesValue] = useState([]);

  useEffect(() => {
    const data = [];

    if (typeof finalContentData?.proprietary_alternatives === 'NA') {
      setFinalContentData((prevState) => ({
        ...prevState,
        proprietary_alternatives: [],
      }));
    } else {
      finalContentData?.proprietary_alternatives?.map((alt) => {
        if (data.indexOf(alt?.value) > -1) {

        } else if (alt?.value) data.push(alt?.value);
      });
    }

    // }

    setPropAlternativesValue(data);
  }, [finalContentData?.proprietary_alternatives]);

  const [youtubeVideoUrls, setYoutubeVideoUrls] = useState(
    finalContentData?.youtube_video_urls[0] ? finalContentData?.youtube_video_urls : [],
  );

  const [newYoutubeUrl, setNewYoutubeUrl] = useState('');

  const [somethingChanged, setSomethingChanged] = useState(null);

  const { Title } = Typography;

  // Specifies a path within your Space and the file to upload.
  const onClickImageButton = async (e) => {
    if (e.target.files[0].size > 6000000) {
      return message.error('Image size should be less than 6 MB');
    }
    document.getElementById('imageUpload').click();
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    onSelectImageFiles(chosenFiles);
  };

  const [propAlternativesOptions, setPropAlternativesOptions] = useState([]);

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const url = propAlternativesOptions.find((o) => o.value === value);

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: 5,
          borderRadius: 8,
          width: 'auto',
          display: 'flex',
          color: 'black',
          height: '50px',
          paddingTop: '10px',
        }}
      >
        <div>
          <img src={url?.url} height={10} width={20} />
        </div>
        <div style={{ marginLeft: '5px', fontSize: '14px' }}> {label}</div>
      </Tag>
    );
  };

  const getCategories = async (id) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_CMS_URL}categories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log('Categories', res.data?.data);
            // if (!finalContentData?.category) {
            setCategories(res?.data?.data);
            // }

            // setSubCategories(res?.data?.data[0]?.sub_category);
          }
        });
    } catch (e) {
      console.log('Some Error Occurred', e);
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      return null;
    }
  };

  const getPropAlternatives = async (id) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_CMS_URL}prop-alternatives`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // if (!finalContentData?.category) {

            const tempArr = [];
            res?.data?.data?.map((value) => {
              const data = {
                value: value?.alternative_name,
                url: value?.alternative_link,
              };

              tempArr.push(data);
            });
            setPropAlternativesOptions(tempArr);
            // }

            // setSubCategories(res?.data?.data[0]?.sub_category);
          }
        });
    } catch (e) {
      console.log('Some Error Occurred', e);
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      return null;
    }
  };

  useEffect(() => {
    getCategories();
  }, [finalContentData?.category]);

  useEffect(() => {
    getPropAlternatives();
  }, []);

  const handleCancelImageModal = () => {
    setOpenImagepModal(false);
    setUploadedImageFiles([]);
    setImageName('');
    setMetaTagValue('');
  };

  const handleCancelDocumentModal = () => {
    setOpenDocsModal(false);
    setUploadedDocumentFiles([]);
    setDocumentName('');
    setMetaTagValue('');
  };

  const handleCancelFounderModal = () => {
    setUploadedFoundingMember([]);
    setFounderName('');
    setFounderUrl('');
    setOpenFoundingTeamModal(false);
  };

  const handleCancelCategoryModal = () => {
    setCategoryChosen('');
    setSubCategoryChosen('');
    setOpenCategoryModal(false);
    setSubCategories([]);
  };

  const onClickDocumentButton = async (e) => {
    if (e.target.files[0].size > 6000000) {
      return message.error('Document size should be less than 6 MB');
    }
    document.getElementById('fileUpload').click();
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    onSelectDocumentFiles(chosenFiles);
  };

  const onChangeVideoUrls = async () => {
    if (!isYouTubeURL(newYoutubeUrl)) {
      return message.error('Not a valid youtube url');
    }

    const youtube_parser = async () => {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = newYoutubeUrl.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    };

    const getYoutubeID = await youtube_parser();
    const finalYoutubeUrl = `https://www.youtube.com/embed/${getYoutubeID}`;

    setYoutubeVideoUrls((oldArray) => [...oldArray, finalYoutubeUrl]);
    setFinalContentData((prevState) => ({
      ...prevState,
      youtube_video_urls: [...prevState.youtube_video_urls, finalYoutubeUrl],
    }));
    setNewYoutubeUrl('');
  };

  const onChangeTwitterURL = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        twitter_url: e.target.value,
      },
    }));
  };

  const onChangeLinkedInUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        linkedin_url: e.target.value,
      },
    }));
  };

  const onChangeProducHuntUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        producthunt_url: e.target.value,
      },
    }));
  };

  const onChangeYoutubeUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        youtube_url: e.target.value,
      },
    }));
  };

  const onChangeDiscordUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        discord_url: e.target.value,
      },
    }));
  };

  const onChangeSlackUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        slack_url: e.target.value,
      },
    }));
  };

  const onChangeCrunchBaseUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        crunchbase_url: e.target.value,
      },
    }));
  };

  const onChangeInstagramUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        instagram_url: e.target.value,
      },
    }));
  };

  const onChangeGitterUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        gitter_url: e.target.value,
      },
    }));
  };

  const onChangeOtherUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        other_url: e.target.value,
      },
    }));
  };

  const onChangeHerokuUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      deploy_url: {
        ...prevState.deploy_url,
        heroku: e.target.value,
      },
    }));
  };

  const onChangeDigitalOceanURL = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      deploy_url: {
        ...prevState.deploy_url,
        digital_ocean: e.target.value,
      },
    }));
  };

  const onChangeVercelURL = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      deploy_url: {
        ...prevState.deploy_url,
        vercel: e.target.value,
      },
    }));
  };

  const onChangeRailwayURL = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      deploy_url: {
        ...prevState.deploy_url,
        railway: e.target.value,
      },
    }));
  };

  const onChangeSandboxURL = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      deploy_url: {
        ...prevState.deploy_url,
        sandbox: e.target.value,
      },
    }));
  };

  const onChangeHashtags = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      social_media_urls: {
        ...prevState.social_media_urls,
        hashtags: e.target.value,
      },
    }));
  };

  // create parameters for upload

  useEffect(() => {
    if (somethingChanged === true) {
      saveDraft();
      setSomethingChanged(null);
    }
  }, [somethingChanged]);

  const onSelectImageFiles = async (files) => {
    const uploaded = [...uploadedImageFiles];

    files.some((file) => {
      uploaded.push(file);
    });

    setUploadedImageFiles(uploaded);
  };

  const onSelectDocumentFiles = async (files) => {
    const uploaded = [...uploadedDocumentFiles];

    files.some((file) => {
      uploaded.push(file);
    });

    setUploadedDocumentFiles(uploaded);
  };

  const handleUploadFiles = async () => {
    try {
      for (let i = 0; i < uploadedImageFiles?.length; i++) {
        const formData = new FormData();
        formData.append('file', uploadedImageFiles[i]);
        formData.append('product_name', finalContentData?.name);
        axios
          .post(`${process.env.REACT_APP_CMS_URL}upload`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            handleCancelImageModal();
            const data = {
              url: response?.data?.url,
              image_name: imageName,
              meta_tag: metaTagValue,
            };

            message.success(`${i + 1}/${uploadedImageFiles.length} Uploaded`);
            if (response?.data?.url) {
              setFinalContentData((prevState) => ({
                ...prevState,
                images: [...prevState.images, data],
              }));
              setSomethingChanged(true);
            }
          });
      }
    } catch (err) {
      console.log('Error', err);
      if (err?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something went wrong. File Uploading Failed');
    }
  };

  const handleDeleteFiles = async (type, url) => {
    setOpenDocsModal(false);
    try {
      let data = {};

      const originalSplit = url.split('/', 6);

      if (type === 'doc') {
        data = {
          product_name: finalContentData?.name,
          originalname: originalSplit[5],
          type: 'doc',
        };
      } else {
        data = {
          product_name: finalContentData?.name,
          originalname: originalSplit[5],
          type: 'image',
        };
      }

      axios
        .post(`${process.env.REACT_APP_CMS_URL}delete-from-space`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          message.success('File Deleted from Database');
          if (type === 'doc') {
            youtubeVideoUrls.filter((item) => item !== value);
            setFinalContentData((prevState) => ({
              ...prevState,
              documents: prevState.documents.filter((item) => item.url !== url),
            }));
          } else {
            setFinalContentData((prevState) => ({
              ...prevState,
              images: prevState.images.filter((item) => item.url !== url),
            }));
          }
          setSomethingChanged(true);
        });
    } catch (err) {
      console.log('Error', err);
      if (err?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something went wrong. File Uploading Failed');
    }
  };

  const handleDocumentUploadFiles = async () => {
    try {
      for (let i = 0; i < uploadedDocumentFiles?.length; i++) {
        const formData = new FormData();
        formData.append('file', uploadedDocumentFiles[i]);
        formData.append('product_name', finalContentData?.name);
        formData.append('type', 'doc');

        axios
          .post(`${process.env.REACT_APP_CMS_URL}upload`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const data = {
              url: response?.data?.url,
              doc_name: documentName,
              meta_tag: metaTagValue,
            };

            message.success(`${i + 1}/${uploadedDocumentFiles.length} Uploaded`);
            if (response?.data?.url) {
              setFinalContentData((prevState) => ({
                ...prevState,
                documents: [...prevState.documents, data],
              }));
              handleCancelDocumentModal();
              setSomethingChanged(true);
            }
          });
      }
    } catch (err) {
      console.log('Error', err);
      if (err?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something went wrong. File Uploading Failed');
    }
  };

  const handleAddFoundingMember = async () => {
    const data = {
      name: founderName,
      url: founderUrl,
    };
    setFinalContentData((prevState) => ({
      ...prevState,
      founding_team: [...prevState.founding_team, data],
    }));
    setUploadedFoundingMember([]);
    setFounderName('');
    setFounderUrl('');
    setOpenFoundingTeamModal(false);
  };

  const handleRemoveItem = (value) => {
    setUploadedImageFiles(uploadedImageFiles.filter((item) => item.name !== value?.name));
  };

  const handleRemoveDocsItem = (value) => {
    setUploadedDocumentFiles(uploadedDocumentFiles.filter((item) => item.name !== value?.name));
  };

  const handleRemoveFoundingMember = (value) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      founding_team: [...prevState.founding_team.filter((item) => item?.name !== value?.name)],
    }));
  };

  // const handleEditCategories = (value) => {
  //   console.log('Cats is',value)

  //   setCategoryChosen(value);
  //   setSubCategoryChosen(value.sub_categories);

  //   setOpenCategoryModal(true);
  // };

  const handleRemoveCategory = (value) => {
    // console.log("finalContentData?.founding_team", finalContentData?.founding_team, value);
    setFinalContentData((prevState) => ({
      ...prevState,
      categories: [
        ...prevState.categories.filter((item) => item?.category_name !== value?.category_name),
      ],
    }));
  };

  const removeYoutubeUrlFromList = (value) => {
    setYoutubeVideoUrls(youtubeVideoUrls.filter((item) => item !== value));
    setFinalContentData((prevState) => ({
      ...prevState,
      youtube_video_urls: youtubeVideoUrls.filter((item) => item !== value),
    }));
  };

  const onChangeContactEmailID = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      contact_email_id: e.target.value,
    }));
  };

  const onChangeProductWebsite = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      product_website: e.target.value,
    }));
  };

  const onChangeProductRoadmap = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      product_roadmap: e.target.value,
    }));
  };

  const onChangeAboutUs = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      about_us: e.target.value,
    }));
  };

  const onChangeProductUrl = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      github_url: e.target.value,
    }));
  };

  const onChangeProductName = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const onChangeFoundedIn = (date, dateString) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      founded_in: dateString,
    }));
  };

  const onChangePropAlternatives = (value) => {
    const data = propAlternativesOptions.find((o) => o.value === value[value.length - 1]);
    setFinalContentData((prevState) => ({
      ...prevState,
      proprietary_alternatives: [...prevState.proprietary_alternatives, data],
    }));
  };

  const onChangeCategoryChosen = (selectedCategory) => {
    const data = categoires?.find((o) => o._id === selectedCategory);

    setCategoryChosen(data);
    setSubCategories(data?.sub_categories);

    // setFinalContentData((prevState) => ({
    //   ...prevState,
    //   proprietary_alternatives: [...prevState.proprietary_alternatives, data],
    // }));
  };

  const getValueSubCategory = () => {
    const names = subCategoryChosen?.map((item) => item.name);
    console.log('Name is', names);

    return names;
  };
  const onSelectCategoryChosen = (selectedSubCategory) => {
    const subs = [];

    selectedSubCategory?.map((data) => {
      const tempArr = subCategories?.find((o) => o._id === data);
      subs.push(tempArr);
    });

    console.log('Selected Sub', subs);

    setSubCategoryChosen(subs);
  };

  const onAddCategory = () => {
    const data = {
      category_id: categoryChosen?._id,
      category_name: categoryChosen?.category,
      sub_categories: subCategoryChosen,
    };

    setFinalContentData((prevState) => ({
      ...prevState,
      categories: [...prevState.categories, data],
    }));

    handleCancelCategoryModal();
  };

  const handleremoveAlternative = (value) => {
    // setYoutubeVideoUrls(youtubeVideoUrls.filter((item) => item !== value));
    // let propAlts = finalContentData.prop.filter((item) => item?.value !== value)

    // setFinalContentData((prevState) => ({
    //   ...prevState,
    //   categories: [
    //     ...prevState.categories.filter((item) => item?.category_name !== value?.category_name),
    //   ],
    // }));
    setFinalContentData((prevState) => ({
      ...prevState,
      proprietary_alternatives: [
        ...prevState.proprietary_alternatives.filter((item) => item?.value !== value),
      ],
    }));

    setFinalContentData((prevState) => ({
      ...prevState,
      proprietary_alternatives: [...prevState.proprietary_alternatives.filter((n) => n)],
    }));
    // setFinalContentData(Array.from(new Set([...bookList, book])));
    //  set
    // proprietary_alternatives: [
    //   ...prevState.proprietary_alternatives.filter((item) => item?.value !== value),
    // ],
    // }));
  };

  const onChangeDescription = (e) => {
    setFinalContentData((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  console.log('Final Content Data', finalContentData);
  return (
    <div style={{ overflowX: 'hidden', height: '55vh', overflowY: 'scroll' }}>
      {stepsCount === 1 ? (
        <>
          <Form
            //   {...formItemLayout}
            layout="vertical"
            initialValues={{
              product_website: finalContentData?.product_website,
              product_roadmap: finalContentData?.product_roadmap,
              description: finalContentData?.description,
              contact_email_id: finalContentData?.contact_email_id,
              linkedin_url: finalContentData?.social_media_urls?.linkedin_url,
              youtube_url: finalContentData?.social_media_urls?.youtube_url,
              twitter_url: finalContentData?.social_media_urls?.twitter_url,
              other_url: finalContentData?.social_media_urls?.other_url,
              discord_url: finalContentData?.social_media_urls?.discord_url,
              crunchbase_url: finalContentData?.social_media_urls?.crunchbase_url,
              instagram_url: finalContentData?.social_media_urls?.instagram_url,
              gitter_url: finalContentData?.social_media_urls?.gitter_url,
              slack_url: finalContentData?.social_media_urls?.slack_url,
              producthunt_url: finalContentData?.social_media_urls?.producthunt_url,
            }}
            //   layout={formLayout}
            form={form}
            //   initialValues={{ layout: formLayout }}
            //   onValuesChange={onFormLayoutChange}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item
                  label="Github Organisation Name"
                  required
                  id="1"
                  hasFeedback
                  validateStatus="success"
                >
                  <Input
                    placeholder="NA"
                    value={finalContentData?.name}
                    disabled={!is_super_admin}
                    onChange={(e) => (is_super_admin ? onChangeProductName(e) : null)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Github Organisation URL"
                  required
                  id="2"
                  hasFeedback
                  validateStatus="success"
                >
                  <Input
                    placeholder="NA"
                    value={finalContentData?.github_url}
                    disabled={!is_super_admin}
                    onChange={(e) => (is_super_admin ? onChangeProductUrl(e) : null)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item label="About us" required id="3" hasFeedback validateStatus="success">
                  <TextArea
                    placeholder="About us"
                    rows={5}
                    value={finalContentData?.about_us}
                    disabled={!is_super_admin}
                    onChange={(e) => (is_super_admin ? onChangeAboutUs(e) : null)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="product_website"
                      label="Product Website"
                      required
                      rules={[
                        {
                          required: true,
                          type: 'url',
                        },
                      ]}
                    >
                      <Input
                        placeholder="www.productdomain.com"
                        // label="product_website"
                        // className={classes.secondInput}
                        // value={finalContentData.product_website}
                        onChange={(e) => onChangeProductWebsite(e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Contact Email ID"
                      name="contact_email_id"
                      required
                      rules={[
                        {
                          required: true,
                          type: 'email',
                        },
                      ]}
                    >
                      <Input
                        placeholder="something@domain.com"
                        value={finalContentData?.contact_email_id}
                        onChange={(e) => onChangeContactEmailID(e)}
                        className={classes.secondInput}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {finalContentData?.categories && (
                <Col span={24}>
                  <Title level={5} style={{ textDecoration: 'underline' }} id="13">
                    Categories and Sub-Categories*
                  </Title>
                  <List
                    style={{ height: '220px', overflow: 'auto' }}
                    bordered
                    dataSource={finalContentData?.categories}
                    renderItem={(item) => (
                      <List.Item>
                        <b>{item?.category_name} :</b>
                        {item?.sub_categories?.map((data) => (
                          <span>{data?.name}</span>
                        ))}
                        {/* <Button type="primary" ghost onClick={() => handleEditCategories(item)}>
                            Edit
                          </Button> */}
                        <Button danger ghost onClick={() => handleRemoveCategory(item)}>
                          Remove
                        </Button>
                      </List.Item>
                    )}
                  />
                </Col>
              )}
            </Row>
            <div style={{ display: 'flex', marginTop: '5px' }}>
              <Button type="primary" onClick={() => setOpenCategoryModal(true)}>
                Add Category
              </Button>
              {/* <Button type="primary" onClick={() => setNumberOfCategories(numberOfCategories - 1)} style={{background:'red',marginLeft:'10px'}}>
                Delete Category
              </Button> */}
            </div>
            <Divider />

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item
                  label="Founded In"
                  // onChange={(e) => onChangeFoundedIn(e)}
                  id="8"
                >
                  <DatePicker
                    value={moment(finalContentData?.founded_in)}
                    format="YYYY"
                    onChange={onChangeFoundedIn}
                    picker="year"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="product_roadmap"
                  label="Product Roadmap Link"
                  rules={[
                    {
                      type: 'url',
                    },
                  ]}
                >
                  <Input
                    placeholder="www.productroadmap.com"
                    onChange={(e) => onChangeProductRoadmap(e)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item label="Proprietary Alternatives" id="11">
                  <Select
                    mode="multiple"
                    value={propAlternativesValue}
                    showArrow
                    tagRender={tagRender}
                    // defaultValue={["gold", "cyan"]}
                    style={{
                      width: '100%',
                    }}
                    options={propAlternativesOptions}
                    onChange={onChangePropAlternatives}
                    onDeselect={handleremoveAlternative}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item
                  label="Description"
                  required
                  // name="desccription"
                  id="12"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: 'string',
                      max: 10,
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Description"
                    rows={5}
                    value={finalContentData?.description}
                    onChange={(e) => onChangeDescription(e)}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item>
                  <Title level={5}>
                    Founding Team &emsp;
                    {uploadedFoundingMember.length <= 0 && (
                      <Button
                        style={{
                          border: '1px solid #7080DE',
                          borderRadius: '10px',
                          background: '#EAEDFA',
                          color: 'black',
                        }}
                        ghost
                        onClick={() => setOpenFoundingTeamModal(true)}
                      >
                        Add
                      </Button>
                    )}
                  </Title>

                  {finalContentData?.founding_team?.length > 0 && (
                    <List
                      style={{ height: '120px', overflow: 'auto' }}
                      bordered
                      dataSource={finalContentData?.founding_team}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Text> {item?.name} </Typography.Text>
                          <Typography.Text> {item?.url} </Typography.Text>

                          <Button danger ghost onClick={() => handleRemoveFoundingMember(item)}>
                            Remove
                          </Button>
                        </List.Item>
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Title level={5} style={{ textDecoration: 'underline' }} id="13">
              Media Files:
            </Title>

            <br />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item>
                  <Title level={5}>
                    Selected Images (.png,jpeg formats allowed){' '}
                    <Button
                      style={{
                        border: '1px solid #7080DE',
                        borderRadius: '10px',
                        background: '#EAEDFA',
                        color: 'black',
                      }}
                      ghost
                      // onClick={() => fileInputRef.current.click()}
                      onClick={(e) => setOpenImagepModal(true)}
                    >
                      Select Files
                    </Button>
                  </Title>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Title level={5}>
                    Documents (Only .pdf files Supported){' '}
                    <Button
                      style={{
                        border: '1px solid #7080DE',
                        borderRadius: '10px',
                        background: '#EAEDFA',
                        color: 'black',
                      }}
                      ghost
                      onClick={(e) => setOpenDocsModal(true)}
                    >
                      Select Files
                    </Button>
                  </Title>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item>
                  <Title level={5}>
                    Uploaded Images -{' '}
                    <Avatar style={{ background: '#87D068' }}>
                      <div>{finalContentData?.images?.length}</div>
                    </Avatar>
                  </Title>
                  <List
                    bordered
                    style={{ height: '400px', overflow: 'auto' }}
                    dataSource={finalContentData?.images}
                    renderItem={(item) => (
                      <List.Item>
                        <Image
                          width={200}
                          style={{
                            height: '130px',
                            width: '230px',
                            borderRadius: '10px',
                            transform: 'translate(50 %, -50 %)',
                          }}
                          src={item?.url}
                          // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                        <Button danger ghost onClick={() => handleDeleteFiles('image', item?.url)}>
                          Remove
                        </Button>
                      </List.Item>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Title level={5}>
                    Uploaded Documents{' '}
                    <Avatar style={{ background: '#87D068' }}>
                      <div>{finalContentData?.documents?.length}</div>
                    </Avatar>
                  </Title>

                  <List
                    style={{ height: '400px', overflow: 'auto' }}
                    bordered
                    dataSource={finalContentData?.documents}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Typography.Text>
                          {' '}
                          {item?.doc_name} &emsp;
                          <Button
                            type="secondary"
                            style={{
                              border: '1px solid lightblue',
                              color: 'lightblue',
                            }}
                            onClick={() => window.open(item?.url, '_blank')}
                          >
                            View
                          </Button>
                        </Typography.Text>

                        <Button danger ghost onClick={() => handleDeleteFiles('doc', item?.url)}>
                          Remove
                        </Button>
                      </List.Item>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item>
                  <Title level={5}>Embedded Video URL </Title>
                  <Row>
                    <Col span={21}>
                      <Input
                        value={newYoutubeUrl}
                        placeholder="Enter Video URL"
                        onChange={(e) => setNewYoutubeUrl(e.target.value)}
                      />
                    </Col>
                    <Col span={3}>
                      {newYoutubeUrl && (
                        <Button
                          style={{
                            color: 'green',
                            background: '#E3F5E6',
                            borderRadius: '4px',
                            border: '1px solid #32BE5A',
                            marginLeft: '5px',
                          }}
                          onClick={() => onChangeVideoUrls()}
                          ghost
                        >
                          Embed
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <List
                    style={{ marginTop: '10px' }}
                    bordered
                    dataSource={finalContentData?.youtube_video_urls}
                    renderItem={(item) => (
                      <List.Item>
                        {/* <Typography.Text> {item} </Typography.Text>
                         */}
                        <iframe
                          src={item}
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                          title="video"
                        />
                        <Button danger ghost onClick={() => removeYoutubeUrlFromList(item)}>
                          Remove
                        </Button>
                      </List.Item>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Modal
            title="Upload Images"
            width={1000}
            visible={openImageModal}
            onCancel={() => handleCancelImageModal()}
            footer={[
              <Button key="back" onClick={() => handleCancelImageModal()}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => handleUploadFiles()}
                disabled={!(uploadedImageFiles.length > 0 && imageName && metaTagValue)}
              >
                Upload
              </Button>,
            ]}
          >
            <Form>
              <Form.Item>
                <Title level={5}>
                  Selected Images (.png,jpeg formats allowed)
                  {uploadedImageFiles.length <= 0 && (
                    <Button
                      style={{
                        border: '1px solid #7080DE',
                        borderRadius: '10px',
                        background: '#EAEDFA',
                        color: 'black',
                      }}
                      ghost
                      onClick={() => fileInputRef.current.click()}
                      // onClick={(e) => setOpenImagepModal(true)}
                    >
                      <input
                        id="imageUpload"
                        type="file"
                        onChange={(e) => onClickImageButton(e)}
                        ref={fileInputRef}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        style={{ display: 'none' }}
                      />
                      Select Files
                    </Button>
                  )}
                </Title>

                {uploadedImageFiles.length > 0 && (
                  <List
                    bordered
                    dataSource={uploadedImageFiles}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text> {item?.name} </Typography.Text>
                        <Button danger ghost onClick={() => handleRemoveItem(item)}>
                          Remove
                        </Button>
                      </List.Item>
                    )}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Input
                      placeholder="Image Name (Ex - Main Product Image)"
                      onChange={(e) => setImageName(e.target.value)}
                      value={imageName}
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Input
                      onChange={(e) => setMetaTagValue(e.target.value)}
                      placeholder="Meta Tag (Ex - Chatwoot-Introduction-Image)"
                      value={metaTagValue}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Upload Documents"
            width={1000}
            visible={openDocsModal}
            onCancel={() => handleCancelDocumentModal()}
            footer={[
              <Button key="back" onClick={() => handleCancelDocumentModal()}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => handleDocumentUploadFiles()}
                disabled={
                  !(uploadedDocumentFiles.length > 0 && documentName !== '' && metaTagValue !== '')
                }
              >
                Upload
              </Button>,
            ]}
          >
            <Form>
              <Form.Item>
                <Title level={5}>
                  Selected Documents (only .pdf formats allowed){' '}
                  {uploadedDocumentFiles.length <= 0 && (
                    <Button
                      style={{
                        border: '1px solid #7080DE',
                        borderRadius: '10px',
                        background: '#EAEDFA',
                        color: 'black',
                      }}
                      ghost
                      onClick={() => documentInputRef.current.click()}
                      // onClick={(e) => setOpenImagepModal(true)}
                    >
                      <input
                        id="fileUpload"
                        type="file"
                        onChange={(e) => onClickDocumentButton(e)}
                        ref={documentInputRef}
                        accept="application/pdf"
                        style={{ display: 'none' }}
                      />
                      Select Files
                    </Button>
                  )}
                </Title>

                {uploadedDocumentFiles.length > 0 && (
                  <List
                    bordered
                    dataSource={uploadedDocumentFiles}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text> {item?.name} </Typography.Text>
                        <Button danger ghost onClick={() => handleRemoveDocsItem(item)}>
                          Remove
                        </Button>
                      </List.Item>
                    )}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Input
                      placeholder="Document Name (Ex - Main Product Brochure)"
                      onChange={(e) => setDocumentName(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Input
                      onChange={(e) => setMetaTagValue(e.target.value)}
                      placeholder="Meta Tag (Ex - Chatwoot-Introduction-Docs)"
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Add Founding Team"
            width={1000}
            visible={openFoundingTeamModal}
            onCancel={() => handleCancelFounderModal()}
            footer={[
              <Button key="back" onClick={() => handleCancelFounderModal()}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => handleAddFoundingMember()}
                disabled={!founderName}
              >
                Add Member
              </Button>,
            ]}
          >
            <Form val>
              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Input
                      name="founder_name"
                      placeholder="Founder Name (Ex- Rachit Sharma)"
                      onChange={(e) => setFounderName(e.target.value)}
                      value={founderName}
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Row>
                <Col span={24}>
                  <Form.Item name="URL" rules={[{ type: 'url' }]} value={founderUrl}>
                    <Input
                      name="founder_url"
                      onChange={(e) => setFounderUrl(e.target.value)}
                      placeholder="LinkedIn or Twitter URL of founder's handle"
                      value={founderUrl}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            title="Add Category And Sub Category"
            width={1000}
            visible={openCategoryModal}
            onCancel={() => handleCancelCategoryModal()}
            footer={[
              <Button key="back" onClick={() => handleCancelCategoryModal()}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => onAddCategory()}
                disabled={!(categoryChosen && subCategories)}
              >
                Add Category
              </Button>,
            ]}
          >
            <Form>
              <Row>
                <Col span={24}>
                  <Form.Item label="Business Category" hasFeedback required>
                    <Select value={categoryChosen.category} onChange={onChangeCategoryChosen}>
                      {categoires?.map((data) => (
                        <Option value={data?._id}>{data?.category}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item label="Business Sub-Category" required>
                    <Select
                      mode="multiple"
                      id="sub-category"
                      onChange={onSelectCategoryChosen}
                    >
                      {/* <Option value="">Select Sub-Category</Option> */}
                      {subCategories?.map((data) => (
                        <Option value={data._id} key={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </>
      ) : (
        <Form
          //   {...formItemLayout}
          layout="vertical"
          //   layout={formLayout}
          form={form}
          //   initialValues={{ layout: formLayout }}
          //   onValuesChange={onFormLayoutChange}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Twitter Url"
                name="twitter_url"
                rules={[{ type: 'url' }]}
                value={finalContentData?.social_media_urls?.twitter_url}
                onChange={(e) => onChangeTwitterURL(e)}
              >
                <Input placeholder="NA" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{ type: 'url' }]}
                label="LinkedIn URL"
                name="linkedin_url"
                value={finalContentData?.social_media_urls?.linkedin_url}
                onChange={(e) => onChangeLinkedInUrl(e)}
              >
                <Input placeholder="https://www.linkedin.com/user" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Product Hunt URL"
                rules={[{ type: 'url' }]}
                name="producthunt_url"
                value={finalContentData?.social_media_urls?.producthunt_url}
                onChange={(e) => onChangeProducHuntUrl(e)}
              >
                <Input placeholder="https://www.something.com" className={classes.secondInput} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Youtube URL"
                rules={[{ type: 'url' }]}
                name="youtube_url"
                value={finalContentData?.social_media_urls?.youtube_url}
                onChange={(e) => onChangeYoutubeUrl(e)}
              >
                <Input placeholder="https://www.youtube.com/xyz" className={classes.secondInput} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Discord URL"
                rules={[{ type: 'url' }]}
                name="discord_url"
                value={finalContentData?.social_media_urls?.discord_url}
                onChange={(e) => onChangeDiscordUrl(e)}
              >
                <Input placeholder="https://www.discord.com/xyz" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Slack URL"
                rules={[{ type: 'url' }]}
                name="slack_url"
                value={finalContentData?.social_media_urls?.slack_url}
                onChange={(e) => onChangeSlackUrl(e)}
              >
                <Input placeholder="https://www.slack.com/xyz" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Crunchbase URl"
                rules={[{ type: 'url' }]}
                name="crunchbase_url"
                value={finalContentData?.social_media_urls?.crunchbase_url}
                onChange={(e) => onChangeCrunchBaseUrl(e)}
              >
                <Input placeholder="https://www.something.com/" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Instagram URL"
                rules={[{ type: 'url' }]}
                name="instagram_url"
                value={finalContentData?.social_media_urls?.instagram_url}
                onChange={(e) => onChangeInstagramUrl(e)}
              >
                <Input placeholder="https://www.instagram.com/xyz" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Gitter URL"
                rules={[{ type: 'url' }]}
                name="gitter_url"
                value={finalContentData?.social_media_urls?.gitter_url}
                onChange={(e) => onChangeGitterUrl(e)}
              >
                <Input placeholder="https://www.something.com/" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Other URL"
                rules={[{ type: 'url' }]}
                name="other_url"
                value={finalContentData?.social_media_urls?.other_url}
                onChange={(e) => onChangeOtherUrl(e)}
              >
                <Input placeholder="https://www.something.com/" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item label="Hashtags" required onChange={(e) => onChangeHashtags(e)}>
                <Input
                  placeholder="#Example, #NewOne"
                  value={finalContentData?.social_media_urls?.hashtags}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin="0">
            Deployment URLs
          </Divider>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Heroku URL"
                rules={[{ type: 'url' }]}
                name="Heroku URL"
                onChange={(e) => onChangeHerokuUrl(e)}
              >
                <Input
                  placeholder="https://www.something.com/"
                  value={finalContentData?.deploy_url?.heroku}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Digital Ocean URL"
                rules={[{ type: 'url' }]}
                name="Digital Ocean URL"
                onChange={(e) => onChangeDigitalOceanURL(e)}
              >
                <Input
                  placeholder="https://www.something.com/"
                  value={finalContentData?.deploy_url?.digital_ocean}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Vercel URL"
                rules={[{ type: 'url' }]}
                name="Vercel URL"
                onChange={(e) => onChangeVercelURL(e)}
              >
                <Input
                  placeholder="https://www.something.com/"
                  value={finalContentData?.deploy_url?.vercel}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Railway URL"
                rules={[{ type: 'url' }]}
                name="Railway URL"
                onChange={(e) => onChangeRailwayURL(e)}
              >
                <Input
                  placeholder="https://www.something.com/"
                  value={finalContentData?.deploy_url?.railway}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Sandbox URL"
                rules={[{ type: 'url' }]}
                name="Sandbox URL"
                onChange={(e) => onChangeSandboxURL(e)}
              >
                <Input
                  placeholder="https://www.something.com/"
                  value={finalContentData?.deploy_url?.sandbox}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <PricingDetails
            finalContentData={finalContentData}
            setFinalContentData={setFinalContentData}
          />

          {/* <Divider/> */}
        </Form>
      )}
    </div>
  );
};

export default ProductInformation;
