import React, { useEffect, useState, useRef } from 'react';
import { Button , Typography , Input ,
  Divider,
  Tabs,
  Steps,
  Form,
  Space,
  Switch,
  List,
  Radio,
  RadioChangeEvent,
  Checkbox,
  Col, Row, Card } from "antd";

import { BiEdit } from 'react-icons/bi';

export const PricingDetails = ({ finalContentData, setFinalContentData }) => {
  const { Title } = Typography;

  const [form] = Form.useForm();

  const [showTierInputBox, setShowTierInputBox] = useState(false);

  const [newModalName, setNewModalName] = useState('');

  const [changeModalName, setChangeModalName] = useState('');

  const [changeTierName, setChangeTierName] = useState('');

  const [showNewModalNameInput, setShowNewModalNameInput] = useState({
    show: false,
    index: '',
  });

  const [showNewTierNameInput, setShowNewTierNameInput] = useState({
    show: false,
    indexOfModal: '',
    indexOfCycle: '',
    indexOfTier: '',
  });

  const [showAddPricingModalInput, setShowAddPricingModalInput] = useState(false);

  const [pricingModalOptions, setPricingModalOptions] = useState(finalContentData?.pricing_details);

  useEffect(() => {
    setFinalContentData((prevState) => ({
      ...prevState,
      pricing_details: pricingModalOptions,
    }));
  }, [pricingModalOptions]);
  const onChangeRatesToDisplay = (checked, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].display_toggle = checked ? 'month' : 'annual';

    setPricingModalOptions(arr);
  };

  const onChangeShowCta = (checked, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].show_contact_cta = !!checked;

    setPricingModalOptions(arr);
  };

  const onChangeShowCtaLink = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].contact_cta_link = value;

    setPricingModalOptions(arr);
  };
  const onChangeModalName = (index) => {
    const arr = [...pricingModalOptions];
    arr[index].value = changeModalName;

    setPricingModalOptions(arr);
    setShowNewModalNameInput({ show: false, index: '' });
  };

  const onChangeTierName = (indexOfModal, indexOfTier, indexOfCycle) => {
    const arr = [...pricingModalOptions];

    arr[indexOfModal].tier[indexOfTier].cycle[indexOfCycle].value = changeTierName;

    setPricingModalOptions(arr);
    setShowNewTierNameInput({
      show: false,
      indexOfModal: '',
      indexOfCycle: '',
      indexOfTier: '',
    });
  };

  const handleRemoveItem = async (index, indexOfTier) => {
    let arr = [...pricingModalOptions];
    if (index !== -1 && indexOfTier !== -1) {
      const subArray = arr[index].tier;
      // subArray.slice(indexOfTier,1)

      arr[index].tier.splice(indexOfTier, 1);
      console.log('Array Value', arr[index].tier);
      setPricingModalOptions(arr);
    }
  };

  const addNewTier = async (index) => {
    const tierToAddFormat = {
      show_contact_cta: false,
      contact_cta_link: "",
      heading: "",
      description: "",
      currency: "",
      rate_monthly: "",
      rate_annualy: "",
      pricing_value: "",
      pricing_unit: "",
      pricing_unit_two: "",

      display_toggle: "",
      cycle: [
        {
          value: "Monthly",
          checked: "false",
        },
        {
          value: "Annual",
          checked: "false",
        },
        {
          value: "Monthly & Annual",
          checked: "false",
        },
      ],
    };

    const arr = [...pricingModalOptions];

    arr[index].tier.push(tierToAddFormat);

    setPricingModalOptions(arr);
    // setShowNewTierNameInput({
    //   show: false,
    //   indexOfModal: "",
    //   indexOfCycle: "",
    //   indexOfCycle: "",
    // });
  };

  const addNewModalToTheList = async () => {
    const newModalToAdd = {
      value: newModalName,
      checked: false,
      tier: [
        {
          heading: "",
          show_contact_cta: false,
          contact_cta_link: "",
          description: "",
          currency: "",
          rate_monthly: "",
          rate_annualy: "",
          pricing_value: "",
          pricing_unit: "",
          pricing_unit_two: "",

          display_toggle: "month",
          cycle: [
            {
              value: "Monthly",
              checked: "false",
            },
            {
              value: "Annual",
              checked: "false",
            },
            {
              value: "Monthly & Annual",
              checked: "false",
            },
          ],
        },
      ],
    };

    const prevData = [...pricingModalOptions];

    prevData.push(newModalToAdd);

    setPricingModalOptions(prevData);
    setShowAddPricingModalInput(false);
  };

  const onChangePricingModalOptions = (e, index) => {
    const newOptions = [...pricingModalOptions];

    newOptions[index].checked = e.target.checked;

    setPricingModalOptions(newOptions);
  };

  const onChangeTierDataOptions = (e, indexOfPricingModal, indexOfCycle, indexOfTierData) => {
    const newOptions = [...pricingModalOptions];

    for (const x in newOptions[indexOfPricingModal].tier[indexOfTierData].cycle) {
      newOptions[indexOfPricingModal].tier[indexOfTierData].cycle[x].checked = false;
    }

    newOptions[indexOfPricingModal].tier[indexOfTierData].cycle[indexOfCycle].checked = true;

    setPricingModalOptions(newOptions);
  };

  const onChangeDescription = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].description = value;

    setPricingModalOptions(arr);
  };

  const onChangeHeading = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].heading = value;

    setPricingModalOptions(arr);
  };

  const onChangeAnnualRate = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].rate_annualy = value;

    setPricingModalOptions(arr);
  };

  const onChangeMonthlyRate = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].rate_monthly = value;

    setPricingModalOptions(arr);
  };

  const onChangeCurrency = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].currency = value;

    setPricingModalOptions(arr);
  };

  const onChangePricingValue = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].pricing_value = value;

    setPricingModalOptions(arr);
  };

  const onChangePricingUnit = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].pricing_unit = value;

    setPricingModalOptions(arr);
  };

  const onChangePricingUnitTwo = (value, index, indexOfTier) => {
    const arr = [...pricingModalOptions];

    arr[index].tier[indexOfTier].pricing_unit_two = value;

    setPricingModalOptions(arr);
  };

  return (
    <div>
      <Form
        //   {...formItemLayout}
        layout="vertical"
        //   layout={formLayout}
        form={form}
        //   initialValues={{ layout: formLayout }}
        //   onValuesChange={onFormLayoutChange}
      >
        <Title level={5} style={{ textDecoration: 'underline' }}>
          Pricing Details:
        </Title>
        {/* <br /> */}
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Divider />
          <Col>
            <Form.Item required>
              <div style={{ display: 'flex' }}>
                <Title level={5} style={{ marginTop: '5px' }}>
                  Pricing Model:{' '}
                </Title>

                <div style={{ display: 'flex', marginLeft: '10px' }}>
                  {pricingModalOptions.map((data, index) => (
                      <>
                        {showNewModalNameInput.index === index &&
                        showNewModalNameInput.show === true ? (
                          <Input.Group
                            compact
                            style={{
                              height: "max-content",
                              width: "max-content",
                            }}
                          >
                            <Input
                              //
                              defaultValue={data?.value}
                              placeholder="Add Tier Name"
                              onChange={(e) => setChangeModalName(e.target.value)}
                              style={{
                                width: "170px",
                                height: "42px",
                                marginLeft: "10px",
                                borderRadius: "8px",
                              }}
                            />
                            <Button
                              type="primary"
                              style={{ height: "38px", marginLeft: "10px" }}
                              onClick={() => onChangeModalName(index)}
                              disabled={!changeModalName}
                            >
                              Submit
                            </Button>
                          </Input.Group>
                        ) : (
                          <div
                            style={{
                              border: "1px solid #D9D9D9",
                              padding: "10px",
                              display: "flex",
                              borderRadius: "8px",
                              marginLeft: "5px",
                            }}
                          >
                            <Checkbox
                              value={data?.checked}
                              defaultChecked={data?.checked}
                              onChange={(e) => onChangePricingModalOptions(e, index)}
                              key={index}
                            >
                              {data?.value}
                            </Checkbox>{" "}
                            <BiEdit
                              onClick={() =>
                                setShowNewModalNameInput({
                                  show: true,
                                  index: index,
                                })
                              }
                            />
                          </div>
                        )}
                      </>
                    ))}

                  {showAddPricingModalInput ? (
                    <Input.Group compact style={{ height: 'max-content', width: 'max-content' }}>
                      <Input
                        //
                        // defaultValue={dat?.value}
                        placeholder="Add Tier Name"
                        onChange={(e) => setNewModalName(e.target.value)}
                        style={{
                          width: '170px',
                          height: '42px',
                          marginLeft: '10px',
                          borderRadius: '8px',
                        }}
                      />
                      <Button
                        disabled={!newModalName}
                        type="primary"
                        style={{ height: '38px', marginLeft: '10px' }}
                        onClick={() => addNewModalToTheList()}
                      >
                        Submit
                      </Button>
                    </Input.Group>
                  ) : (
                    <Button
                      style={{
                        display: `${pricingModalOptions?.length === 5 && 'none'}`,
                        color: 'green',
                        background: '#E3F5E6',
                        borderRadius: '4px',
                        border: '1px solid #32BE5A',
                        marginLeft: '10px',
                        marginTop: '5px',
                      }}
                      ghost
                      onClick={() => setShowAddPricingModalInput(true)}
                    >
                      Add
                    </Button>
                  )}
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Tabs defaultActiveKey="0">
          {pricingModalOptions.map((data, index) => (
              data?.checked === true && (
                <Tabs.TabPane tab={data?.value} key={index}>
                  <div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={12}>
                        <Form.Item required>
                          <div style={{ display: "flex" }}>
                            <Typography style={{ fontSize: "14px", fontWeight: "600" }}>
                              Pricing Tier{" "}
                            </Typography>{" "}
                            &nbsp;
                            <Typography
                              style={{
                                color: "#3E51AA",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              ({data?.value})
                            </Typography>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <hr />
                    {/* <Divider /> */}
                    <div>
                      {data?.tier.map((tierData, indexOfTier) => {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              {tierData.cycle.map((cycleData, indexOfCycle) => {
                                return (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    {showNewTierNameInput.show === true &&
                                    showNewTierNameInput.indexOfModal === index &&
                                    showNewTierNameInput.indexOfTier === indexOfTier &&
                                    showNewTierNameInput.indexOfCycle === indexOfCycle ? (
                                      <Input.Group
                                        compact
                                        style={{
                                          height: "max-content",
                                          width: "max-content",
                                        }}
                                      >
                                        <Input
                                          placeholder="Add Tier Name"
                                          defaultValue={cycleData?.value}
                                          onChange={(e) => setChangeTierName(e.target.value)}
                                          style={{
                                            width: "170px",
                                            height: "42px",
                                            marginLeft: "10px",
                                            borderRadius: "8px",
                                          }}
                                        />
                                        <Button
                                          disabled={changeTierName ? false : true}
                                          type="primary"
                                          style={{
                                            height: "38px",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            onChangeTierName(index, indexOfTier, indexOfCycle)
                                          }
                                        >
                                          Submit
                                        </Button>
                                      </Input.Group>
                                    ) : (
                                      <Radio.Group
                                        value={cycleData?.checked === true && cycleData?.value}
                                        onChange={(e) =>
                                          onChangeTierDataOptions(
                                            e.target.value,
                                            index,
                                            indexOfCycle,
                                            indexOfTier
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            border: "1px solid #D9D9D9",
                                            padding: "10px",
                                            display: "flex",
                                            borderRadius: "8px",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          <Radio
                                            value={cycleData.value}
                                            style={{ display: "flex" }}
                                            key={index}
                                          >
                                            <div style={{ display: "flex" }}>
                                              {cycleData?.value}
                                              {console.log("Show Edit", showNewTierNameInput)}
                                              <BiEdit
                                                style={{
                                                  marginLeft: "10px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  setShowNewTierNameInput({
                                                    show: true,
                                                    indexOfModal: index,
                                                    indexOfCycle: indexOfCycle,
                                                    indexOfTier: indexOfTier,
                                                  })
                                                }
                                              />{" "}
                                            </div>
                                          </Radio>{" "}
                                        </div>
                                      </Radio.Group>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            <Form.Item label="Show Contact CTA" required>
                              <Switch
                                defaultChecked={tierData?.show_contact_cta}
                                unCheckedChildren="No"
                                checkedChildren="Yes"
                                onChange={(checked) => onChangeShowCta(checked, index, indexOfTier)}
                              />
                            </Form.Item>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                              <Col span={12}>
                                <Form.Item
                                  label="Heading"
                                  // name="Heading"
                                  required
                                  rules={[{ max: 10 }, { required: true }]}
                                >
                                  <Input
                                    placeholder="Heading"
                                    value={tierData?.heading}
                                    onChange={(e) =>
                                      onChangeHeading(e.target.value, index, indexOfTier)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Description"
                                  required
                                  rules={[{ max: 200 }, { required: true }]}
                                >
                                  <Input
                                    placeholder="Description"
                                    value={tierData?.description}
                                    onChange={(e) =>
                                      onChangeDescription(e.target.value, index, indexOfTier)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            {tierData?.show_contact_cta ? (
                              <>
                                <Col span={12}>
                                  <Form.Item
                                    label="CTA Link"
                                    required
                                    rules={[{ required: true }, { type: "url" }]}
                                  >
                                    <Input
                                      placeholder="CTA Link"
                                      value={tierData?.contact_cta_link}
                                      onChange={(e) =>
                                        onChangeShowCtaLink(e.target.value, index, indexOfTier)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ) : (
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={12}>
                                  <Form.Item label="Currency" required>
                                    <Input
                                      value={tierData?.currency}
                                      placeholder="Currency"
                                      onChange={(e) =>
                                        onChangeCurrency(e.target.value, index, indexOfTier)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item label="Rate To Display" required>
                                    <Switch
                                      defaultChecked={
                                        tierData?.display_toggle === "month" ? true : false
                                      }
                                      unCheckedChildren="Annual"
                                      checkedChildren="Month"
                                      onChange={(checked) =>
                                        onChangeRatesToDisplay(checked, index, indexOfTier)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col span={12}>
                                      <Form.Item label="Rate (Monthly)" required>
                                        <Input
                                          value={tierData?.rate_monthly}
                                          placeholder="Rate (Monthly)"
                                          onChange={(e) =>
                                            onChangeMonthlyRate(e.target.value, index, indexOfTier)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                      <Form.Item label="Rate (Annually)" required>
                                        <Input
                                          value={tierData?.rate_annualy}
                                          placeholder="Rate (Annually)"
                                          onChange={(e) =>
                                            onChangeAnnualRate(e.target.value, index, indexOfTier)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col>
                                  <Row>
                                    <Col span={12}>
                                      <Form.Item label="Value">
                                        <Input
                                          value={tierData?.pricing_value}
                                          placeholder="Value"
                                          onChange={(e) =>
                                            onChangePricingValue(e.target.value, index, indexOfTier)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                      <Form.Item label="Unit 1">
                                        <Input
                                          value={tierData?.pricing_unit}
                                          placeholder="Unit"
                                          onChange={(e) =>
                                            onChangePricingUnit(e.target.value, index, indexOfTier)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                      <Form.Item label="Unit 2">
                                        <Input
                                          value={tierData?.pricing_unit_two}
                                          placeholder="Unit"
                                          onChange={(e) =>
                                            onChangePricingUnitTwo(
                                              e.target.value,
                                              index,
                                              indexOfTier
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}

                            <Row
                              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                              style={{ marginTop: "25px" }}
                            >
                              {tierData.cycle.map((cycleData) => {
                                return (
                                  cycleData?.checked === true && (
                                    <Col span={12}>
                                      {tierData?.show_contact_cta ? (
                                        <>
                                          <Card
                                            title="Enterprise"
                                            extra={
                                              <a href="#" style={{ color: "red" }}>
                                                Remove
                                              </a>
                                            }
                                          >
                                            <Row>
                                              <Col span={20}>
                                                <p>
                                                  {tierData.heading !== ""
                                                    ? tierData?.heading
                                                    : "OpenSource"}
                                                </p>
                                              </Col>
                                              <Col span={4}>
                                                <Button
                                                  onClick={() =>
                                                    window.open(tierData?.contact_cta_link)
                                                  }
                                                  type="primary"
                                                >
                                                  Contact Us
                                                </Button>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col span={21}>
                                                <p>
                                                  {tierData.description !== ""
                                                    ? tierData?.description
                                                    : "Lorem Ipsum blah blah blah...."}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Card>
                                        </>
                                      ) : (
                                        <Card
                                          title={cycleData?.value}
                                          extra={
                                            <a href="#" style={{ color: "red" }}>
                                              Remove
                                            </a>
                                          }
                                        >
                                          <Row>
                                            <Col span={21}>
                                              <p>
                                                {tierData.heading !== ""
                                                  ? tierData?.heading
                                                  : "OpenSource"}
                                              </p>
                                            </Col>
                                            <Col span={3}>
                                              <Title level={4}>
                                                {tierData.currency !== ""
                                                  ? tierData?.currency
                                                  : "$"}
                                                {tierData.display_toggle === "month"
                                                  ? tierData?.rate_monthly
                                                  : tierData?.rate_annualy}
                                              </Title>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col span={21}>
                                              <p>
                                                {tierData.description !== ""
                                                  ? tierData?.description
                                                  : "Lorem Ipsum blah blah blah...."}
                                              </p>
                                            </Col>
                                            <Col span={3}>
                                              <p>
                                                {tierData.display_toggle === "month"
                                                  ? `${tierData?.pricing_value} ${tierData?.pricing_unit}${tierData?.pricing_unit_two}`
                                                  : `${tierData?.pricing_value} ${tierData?.pricing_unit}${tierData?.pricing_unit_two}`}
                                              </p>
                                            </Col>
                                          </Row>
                                        </Card>
                                      )}
                                      &nbsp;
                                    </Col>
                                  )
                                );
                              })}
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                              <Col>
                                {
                                  <Button
                                    style={{
                                      color: "green",
                                      background: "#E3F5E6",
                                      borderRadius: "4px",
                                      border: "1px solid #32BE5A",
                                    }}
                                    ghost
                                    onClick={() => addNewTier(index)}
                                  >
                                    Add
                                  </Button>
                                }

                                {data?.tier?.length > 1 && (
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    danger
                                    ghost
                                    onClick={() => handleRemoveItem(index, indexOfTier)}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            <Divider />
                          </>
                        );
                      })}

                      {showTierInputBox && (
                        <Input.Group
                          compact
                          style={{ height: "max-content", width: "max-content" }}
                        >
                          <Input
                            //
                            placeholder="Add Tier Name"
                            style={{
                              width: "170px",
                              height: "42px",
                              marginLeft: "10px",
                              borderRadius: "8px",
                            }}
                          />
                          <Button type="primary" style={{ height: "38px", marginLeft: "10px" }}>
                            Submit
                          </Button>
                        </Input.Group>
                      )}
                    </div>
                    &nbsp;
                  </div>
                </Tabs.TabPane>
              )
            ))}
        </Tabs>
      </Form>
    </div>
  );
};
