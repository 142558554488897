import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Selection,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';

import { message } from 'antd';
import axios from 'axios';
import { logoutUser } from '../commons/Utils';
import { Header } from '../components';
import { draftsGrid } from '../data/dummy';
import { FilterTable } from './FilterTable';

const Drafts = () => {
  // const toolbarOptions = ['Delete'];
  const editing = { allowDeleting: false, allowEditing: false };

  const [draftsData, setDraftsData] = useState([]);

  const [searchName, setSearchName] = useState('');
  const token = localStorage.getItem('userToken');

  const role = localStorage.getItem('role');

  const [filterData, setFilterData] = useState({
    date_created: '',
    category: '',
    status: '',
    owner_email_id: role === 'super-admin' ? '' : localStorage.getItem('email'),
    start_date: '',
    end_date: '',
    _id: '',
  });

  const makeApiCall = async (filterDataReceived) => {
    try {
      const result = await axios
        .get(
          `${
            process.env.REACT_APP_CMS_URL
          }drafts/?name=${searchName}&created_by=${
            role == 'admin' ? localStorage.getItem('userID') : ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: filterDataReceived,
          },
        )
        .then((res) => {
          if (res.status === 200) {
            return res?.data?.data;
          }
        });

      return setDraftsData(result);
    } catch (e) {
      console.log('Some Error Occurred', e);
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }
      message.error('Something we wrong');
      return null;
    }
  };
  useEffect(() => {
    makeApiCall();
    //  setDashboardData(data)
  }, []);

  useEffect(() => {
    if (searchName === '' || searchName.length % 3 === 0) {
      makeApiCall();
    }
    //  setDashboardData(data)
  }, [searchName]);

  return (
    <div
      className="m-2 md:m-2 mt-24 p-2 md:p-10 bg-white rounded-3xl"
      style={{ overflowX: 'hidden' }}
    >
      {/* <Header category="Page" title="Drafts" /> */}
      <Header category="" title="Drafts" />

      <FilterTable
        searchName={searchName}
        setSearchName={setSearchName}
        makeApiCall={makeApiCall}
        filterData={filterData}
        setFilterData={setFilterData}
      />

      <GridComponent
        id="gridcomp"
        dataSource={draftsData}
        // rowSelected={getSelectedRows}
        enableAdaptiveUI
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        // contextMenuItems={contextMenuItems}
        editSettings={editing}
        cellSelecting
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {draftsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Drafts;
