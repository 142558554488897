import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  Page,
  PdfExport,
  Resize,
  Search,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { message } from 'antd';
import { logoutUser } from '../commons/Utils';
import { Header } from '../components';
import { contextMenuItems, dashboardGrid } from '../data/dummy';
import { FilterTable } from './FilterTable';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  const [searchName, setSearchName] = useState('');
  const token = localStorage.getItem('userToken');

  const [filterData, setFilterData] = useState({
    date_created: '',
    category: '',
    status: '',
    created_by: '',
    start_date: '',
    end_date: '',
    _id: '',
  });

  const makeApiCall = async (filterDataReceived) => {
    try {
      const result = await axios
        .get(
          `${process.env.REACT_APP_CMS_URL}content/?name=${searchName}&page=all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: filterDataReceived,
          },
        )
        .then((res) => {
          if (res.status === 200) {
            return res?.data?.data;
          }
        });

      return setDashboardData(result);
    } catch (e) {
      console.log('Some Error Occurred', e);
      if (e?.response?.status === 401) {
        message.error('User not found');
        return logoutUser();
      }

      message.error('Something went wrong.');

      return null;
    }
  };

  useEffect(() => {
    if (searchName === '' || searchName.length % 3 === 0) {
      makeApiCall();
    }
    //  setDashboardData(data)
  }, [searchName]);

  useEffect(() => {
    makeApiCall;
  }, []);

  const editing = { allowDeleting: false, allowEditing: false };

  return (
    <div
      className="m-2 md:m-2 mt-24 p-2 md:p-10 bg-white rounded-3xl"
      style={{ overflowX: 'hidden' }}
    >
      <Header category="" title="Dashboard" />

      <FilterTable
        searchName={searchName}
        setSearchName={setSearchName}
        makeApiCall={makeApiCall}
        filterData={filterData}
        setFilterData={setFilterData}
      />

      <GridComponent
        id="gridcomp"
        dataSource={dashboardData}
        enableAdaptiveUI
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        editSettings={editing}
        cellSelecting
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {dashboardGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Sort,
            ContextMenu,
            Filter,
            Page,
            ExcelExport,
            Edit,
            PdfExport,
            Search,
            Toolbar,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default Dashboard;
