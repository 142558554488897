import React, { useEffect } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import ScoutfloLogo from "../assets/images/scoutflo-logo.svg";
import AtlasLogo from "../assets/images/Atlas.png";

import { useStateContext } from '../contexts/ContextProvider';


const PreLoginNavBar = () => {
  const { activeMenu, setActiveMenu, setScreenSize, screenSize } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      <div style={{ display: 'flex' }}>
        <img
          src={ScoutfloLogo}
          alt="Scoutflo logo"
          width={150}
          height={200}
        />

        <img
          src={AtlasLogo}
          alt="Atlas logo"
          width={40}
          height={20}
          style={{ marginLeft: '5px' }}
        />
      </div>

    </div>
  );
};

export default PreLoginNavBar;
