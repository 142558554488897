import React, { useEffect, useState } from "react";
import {
  Collapse,
  Typography,
  Divider,
  Col,
  Row,
  Form,
  Input,
  List,
  InputNumber,
} from "antd";
import axios from "axios";

const Community = ({ contributorsUrl, setFinalContentData, finalContentData}) => {
  const { Panel } = Collapse;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [gotTopContri, setGotTopContri] = useState(false);
  const [contributorsData, setContributorsData] = useState([]);


  const onChangeTotalMembers = (value) => 
  {
    console.log('Value is',value)
      setFinalContentData((prevState) => ({
        ...prevState,
        total_members: value,
      }));
  }

  const onChangeActiveMembers = (value) => 
  {
      setFinalContentData((prevState) => ({
        ...prevState,
        active_members: value,
      }));
  }

  useEffect(async () => {
    const getTopContributors = async () => {
      try {
        axios
          .get(`${contributorsUrl}`)
          .then(function (response) {
            setGotTopContri(true);

            let arr = [];

            const contentLength =
              response?.data?.length >= 10 ? 10 : response?.data?.length;

            for (let i = 0; i < contentLength; i++) {
              arr.push(response?.data[i]);
            }
            setContributorsData(arr);
         
              setFinalContentData((prevState) => ({
                ...prevState,
                top_contributors:arr,
              }));
          
          })
          .catch(function (error) {
            console.log(error);
            setGotTopContri(false);
            setContributorsData([]);
          });
      } catch (e) {
        console.log("Error", e);
        setGotTopContri(false);
        contributorsData([]);
      }
    };

    getTopContributors();
  }, []);

  return (
    <>
      <Title level={4}>Community Detials</Title>
      <Divider />
      <Form layout="vertical" form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item label="Top Contributors" required>
              <div
                style={{
                  overflow: "scroll",
                  height: "400px",
                  borderBottom: "1px solid #EAEAEA",
                  borderTop: "1px solid #EAEAEA",
                }}>
                <List
                  bordered
                  dataSource={contributorsData}
                  renderItem={(item) => (
                    <List.Item>
                      <div>
                        <img
                          src={item?.avatar_url}
                          alt="Avatar"
                          style={{
                            borderRadius: "50%",
                            height: "70px",
                            width: "70px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(`${item?.html_url}`, "_blank")
                          }></img>
                        <label> {item?.login} </label>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <Typography.Text style={{ color: "green" }}>
                          +{item?.contributions}
                        </Typography.Text>
                        <br />
                        <Typography.Text>Contributions</Typography.Text>
                      </div>
                      {/* <Button danger ghost onClick={() => handleRemoveItem(item)}>
                        Remove
                      </Button> */}
                    </List.Item>
                  )}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={12}>
                <Form.Item label="Total Members" required>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Total Members"
                    value={finalContentData?.total_members}
                    onChange={onChangeTotalMembers}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item label="Active Members" required>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Active Members"
                    value={finalContentData?.active_members}
                    onChange={onChangeActiveMembers}

                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Community;
