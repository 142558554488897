import { FilterFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Divider, Input, message, Popover, Radio, Row } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { logoutUser } from "../commons/Utils";

export const FilterTable = ({ setSearchName, searchName, makeApiCall, filterData, setFilterData }) => {
  const { RangePicker } = DatePicker;

  // const categories = [
  //   "System",
  //   "Application",
  //   "Driver",
  //   "Middleware",
  //   "Programming",
  // ];

  const status = ['Published', 'Unpublished', 'In Review']

  const [isEmpty, setIsEmpty] = useState(true)


  const [radioValue, setRadioValue] = useState(1)

  const [filterDataToSend, setFilterDataToSend] = useState(filterData)

  const [cascadeOption, setCascadeOption] = useState([])

  const [totalFiltersApplied, setTotalFiltersApplied] = useState(0)

  const [categories, setCategories] = useState([])


  const role = localStorage.getItem('role')

  const token = localStorage.getItem("userToken");

  const [subCategories, setSubCategories] = useState([])

  const [popupVisible, setPopupVisible] = useState(false)

  const [userData, setUsersData] = useState()
  const [initLoading, setInitLoading] = useState(true);



  const getUserData = async () => {
    try {
      const result = await axios
        .get(`${process.env.REACT_APP_CMS_URL}register/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setInitLoading(false);
            return res?.data?.data;
          }
        });

      return setUsersData(result);
    } catch (e) {
      setInitLoading(false);
      message.error("");
      return null;
    }
  };
  useEffect(() => {
    setInitLoading(true);
    getUserData();
    //  setDashboardData(data)
  }, []);


  const getCategories = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_CMS_URL}categories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setCategories(res?.data?.data)
            setSubCategories(res?.data?.data)
          }
        });
    } catch (e) {
      console.log("Some Error Occurred", e);

      if (e?.response?.status === 401) {
        message.error('User not found')
        return logoutUser()
      }
      else {
        message.error('Something we wrong')
      }
      return null;
    }
  };

  const getSubCategories = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_CMS_URL}categories?category=${filterDataToSend?.category}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setSubCategories(res?.data?.data)
          }
        });
    } catch (e) {
      console.log("Some Error Occurred", e);
      if (e?.response?.status === 401) {
        message.error('User not found')
        return logoutUser()
      }
      else {
        message.error('Something we wrong')
      }
      return null;
    }
  };


  useEffect(() => {
    getCategories()
  }, []);

  useEffect(() => {
    getSubCategories()
  }, [filterDataToSend?.category]);



  const onChangeCategory = (checkedValues) => {

    setFilterDataToSend((prevData) => ({
      ...prevData,
      category: checkedValues.toString()
    }))
  }

  const onChangeSubCategory = (checkedValues) => {

    setFilterDataToSend((prevData) => ({
      ...prevData,
      sub_category: checkedValues.toString()
    }
    ))
  };


  const onChangeDate = (date, dateString) => {

    setFilterDataToSend((prevData) => ({
      ...prevData,
      date_created: dateString.toString(),
      start_date: '',
      end_date: ''
    }
    ))
  };

  const onChangeStatus = (checkedValues) => {

    setFilterDataToSend((prevData) => ({
      ...prevData,
      status: checkedValues[0]
    }
    ))
  };

  const onChangeName = (checkedValues) => {
    setFilterDataToSend((prevData) => ({
      ...prevData,
      created_by: checkedValues[0]
    }
    ))
  };



  const onChangeDateRange = (date, dateString) => {

    setFilterDataToSend((prevData) => ({
      ...prevData,
      date_created: '',
      start_date: dateString[0].toString(),
      end_date: dateString[1].toString()
    }
    ))
  };


  const onChangeRadio = (e) => {
    setRadioValue(e.target.value);
  };

  const sendFilters = (e) => {
    setFilterData(filterDataToSend)
    makeApiCall(filterDataToSend)

    let count = 0
    for (var key in filterDataToSend) {
      if (filterDataToSend.hasOwnProperty(key)) {
        if (filterDataToSend[key] !== '')
          count = count + 1
      }
    }

    setTotalFiltersApplied(count)
    setPopupVisible(false)


  }

  const clearFilters = () => {
    setFilterData({})
    makeApiCall({})
    setTotalFiltersApplied(0)
    setPopupVisible(false)

  }


  useEffect(() => {
  console.log('Is Empty value',isEmpty)

   if(Object.values(filterData).every(x => x === null || x === ''))
   {
     setIsEmpty(true)
   }
   else
   {
     setIsEmpty(false)
   }
  }, [filterData])
  



  const content = (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Button type="link" style={{ color: 'red', padding: 0 }} onClick={() => clearFilters()}>Clear Filters</Button>
        </div>


    <div style={{ display: 'flex' }}>
          <Button type="link" onClick={() => sendFilters()}>Apply Filter</Button>
        </div>

      </div>


      <div style={{ fontWeight: 500 }}>Category</div>
      <Checkbox.Group
        style={{ marginTop: "10px", width: '100%' }}
        onChange={(checkedValues) => onChangeCategory(checkedValues)}
        defaultValue={filterData?.category}
      >
        <Row>
          {categories?.map((data) => {
            return (
              <Col span={8}>
                <Checkbox value={data?.category}>{data?.category}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>

      {filterDataToSend?.category && <>
        <Divider />

        <div style={{ fontWeight: 500 }}>Sub-Category</div>
        <Checkbox.Group
          style={{ marginTop: "10px", width: '100%' }}
          onChange={(checkedValues) => onChangeSubCategory(checkedValues)}
          defaultValue={filterData?.sub_category}
        >
          <Row>

            {subCategories.map((catData) =>
              catData.sub_category.map((data) => {
                return (
                  <Col span={8}>
                    <Checkbox value={data}>{data}</Checkbox>
                  </Col>
                )
              }))}
          </Row>
        </Checkbox.Group></>}

      <Divider />

      <Radio.Group onChange={(e) => onChangeRadio(e)} value={radioValue}>
        <Radio value={1}>Date</Radio>
        <Radio value={2}>Date Range</Radio>
      </Radio.Group>
      <br />
      {radioValue == 1 ?
        <DatePicker onChange={onChangeDate} style={{ marginTop: "10px" }} />
        :
        <RangePicker style={{ marginTop: "10px" }} onChange={onChangeDateRange} />
      }

      <Divider />

      <div style={{ fontWeight: 500 }}>Status</div>
      <Checkbox.Group
        style={{ marginTop: "10px", width: '100%' }}
        onChange={onChangeStatus}
      >
        <Row>
          {status.map((data) => (
            <Col span={8}>
              <Checkbox value={data}>{data}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Divider />

      <div>
        <div style={{ fontWeight: 500 }}>Name</div>
        <Checkbox.Group
          style={{ marginTop: "10px", width: '100%' }}
          onChange={onChangeName}
        >
          <Row>
            {userData?.map((data) => (
              <Col span={8}>
                <Checkbox value={data.username}>{data.username}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
    </div>
  );

  return (
    <div style={{ marginBottom: "10px", display: "flex" }}>
      <div>
        <Input.Search
          placeholder="Search Product"
          allowClear
          // enterButton="Search"
          style={{ width: "200px", marginRight: "10px" }}
          onChange={(e) => setSearchName(e.target.value)}
          onSearch={() => makeApiCall()}
          on
        />

        {/* <Button
          type="primary"
          icon={<SearchOutlined style={{ marginBottom: "-10px" }} />}
          style={{ background: "rgb(50, 76, 208)" }}
        >
          Search
        </Button> */}

        <div style={{ position: "absolute", right: "53px", top: "159px" }}>
          <Popover placement="bottom" content={content} trigger="hover"> 
            <Button type="primary"
              style={{ background: '#F1F5F6', border: 'none', color: 'black', height: '50px' }}> <div style={{ display: 'inline-flex' }}
                onClick={() => setPopupVisible(true)}>
                <FilterFilled
                  size={10}
                  style={{ color: '#707070', marginTop: '8px', marginRight: '10px' }} /> <p style={{ marginTop: '5px' }}>
                  Filters</p>
                <div
                  style={{ background: '#505050', padding: '1px 10px 23px 10px', color: 'white', height: '19px', marginLeft: '10px', borderRadius: '4px', marginTop: '5px' }}>
                  {totalFiltersApplied}
                </div></div>
            </Button>
          </Popover>
        </div>
      </div>
    </div>
  );
};
