import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Button, message, Modal, Tooltip, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { FcInfo } from 'react-icons/fc';
import {
  MdAdminPanelSettings,
  MdDashboard,
  MdDelete,
  MdDrafts,
  MdFileDownload,
  MdFileUpload,
  MdModeEdit,
} from 'react-icons/md';
import { Link } from 'react-router-dom';

import 'antd/dist/antd.css';
import { GrRevert, GrUserSettings } from 'react-icons/gr';

// const token = localStorage.getItem('userToken');
const role = localStorage.getItem('role');
const userEmail = localStorage.getItem('email');

// let actionButtonClicked=''
export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props?.ProductImage}
      alt="order-item"
    />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{
      background: props?.status === 'Published' ? '#8BE78B' : '#FEC90F',
      color: 'black',
    }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props?.status}
  </button>
);

export const createdByGrid = (props) => (
  <div style={{ display: 'flex' }}>
    {props?.owner_role === 'super-admin' ? (
      <MdAdminPanelSettings style={{ fontSize: '1.5em' }} />
    ) : null}
    {props?.owner_username}
    &emsp;
    <Tooltip
      title={(
        <>
          {props?.owner_email_id}
          <br />
          {props?.owner_role}
        </>
      )}
      color="orange"
    >
      <FcInfo style={{ fontSize: '1.5em' }} />
    </Tooltip>
  </div>
);

export const gridOrderStatusDrafts = (props) => (
  <button
    type="button"
    style={{
      background: '#505050',
      color: 'white',
      opacity: '0.8',
    }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props?.status === 'Unpublished' && 'In-Review'}
  </button>
);

export const gridShowDate = (props) => (
  <div>{moment(props?.date_created).format('DD/MM/YYYY')}</div>
);

export let count = 0;

export const gridActionItems = (props) => {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showUnPublishModal, setShowUnPublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  const userNotAllowed = !!(
    role === 'admin' && props?.owner_email_id !== userEmail
  );


  const changeStatusOfProduct = async (props, status) => {
    try {
      console.log('User token is' ,localStorage.getItem('userToken'))

      axios
        .patch(
          `${process.env.REACT_APP_CMS_URL}content/${props?._id}`,
          {
            status,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          },
        )
        .then(() => {
          setShowPublishModal(false);
          if (status === 'Published') {
            message.success('Product Published');
          } else if (status === 'Unpublished') {
            message.success('Product Unpublished');
          } else {
            message.success('Product Moved to Trash');
          }

          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 401) {
            message.error('Something went wrong');
            // return logoutUser()
          } else {
            message.error('Something went wrong.');
          }
        });
    } catch (e) {
      console.log('Error', e);
    }
  };

  const { Title } = Typography;

  // if (role === 'admin' && props?.created_by !== userName) {
  //   return null
  // }

  return (
    <div>
      {/* { moment(props?.date_created).format('DD/MM/YYYY')} */}
      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        disabled={(props?.status === 'Published' || userNotAllowed) && true}
        style={{
          background: '#F4F4F4',
          color:
            props?.status === 'Published' || userNotAllowed ? 'gray' : 'black',
          height: '30px',
          opacity: props?.status === 'Published' || userNotAllowed ? 0.4 : 1,
        }}
      >
        {props?.status === 'Published' ? (
          <TooltipComponent content="You can’t edit a published product. Unpublish the product to edit it.">
            {' '}
            <MdModeEdit />
          </TooltipComponent>
        ) : userNotAllowed ? (
          <TooltipComponent content="You can’t edit a published product. Unpublish the product to edit it.">
            {' '}
            <MdModeEdit />
          </TooltipComponent>
        ) : (
          <Link
            to={{
              pathname: `/add`,
              search:  `?id=${props?._id}&type=content`
            }}
          >
            <TooltipComponent content="Edit Product">
              <MdModeEdit />
            </TooltipComponent>
          </Link>
        )}
      </button>
      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        disabled={(props?.status === 'Published' || userNotAllowed) && true}
        onClick={() => setShowPublishModal(true)}
        style={{
          background: '#F4F4F4',
          color:
            props?.status === 'Published' || userNotAllowed ? 'gray' : 'green',
          marginLeft: '5px',
          height: '30px',
          opacity: props?.status === 'Published' || userNotAllowed ? 0.4 : 1,
        }}
      >
        <TooltipComponent content="Publish product on the listing platform.">
          <MdFileUpload />
        </TooltipComponent>
      </button>
      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        disabled={(props?.status === 'Unpublished' || userNotAllowed) && true}
        onClick={() => setShowUnPublishModal(true)}
        style={{
          background: '#F4F4F4',
          color:
            props?.status === 'Unpublished' || userNotAllowed ? 'gray' : 'red',
          marginLeft: '5px',
          height: '30px',
          opacity: props?.status === 'Unpublished' || userNotAllowed ? 0.4 : 1,
        }}
      >
        <TooltipComponent content="Unpublish product from the listing platform.">
          <MdFileDownload />
        </TooltipComponent>
      </button>
      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        disabled={(props?.status === 'Published' || userNotAllowed) && true}
        onClick={() => setShowDeleteModal(true)}
        style={{
          background: '#F4F4F4',
          color:
            props?.status === 'Published' || userNotAllowed ? 'gray' : 'red',
          height: '30px',
          opacity: props?.status === 'Published' || userNotAllowed ? 0.4 : 1,
        }}
      >
        {props?.status === 'Published' ? (
          <TooltipComponent content="You can’t delete a published product. Unpublish the product to delete it.">
            {' '}
            <MdDelete />
          </TooltipComponent>
        ) : (
          <TooltipComponent content="Move to trash">
            <MdDelete />
          </TooltipComponent>
        )}
      </button>
      <Modal
        visible={showPublishModal}
        mask={false}
        // onOk={() => changeStatusOfProduct(props,'Published')}
        onCancel={() => setShowPublishModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowPublishModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => changeStatusOfProduct(props, 'Published')}
          >
            Publish
          </Button>,
        ]}
      >
        <Title level={5}>Are you sure you want to Publish {props?.name}?</Title>
      </Modal>

      <Modal
        visible={showUnPublishModal}
        mask={false}
        // onOk={() => changeStatusOfProduct(props,'Published')}
        onCancel={() => setShowUnPublishModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowUnPublishModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => changeStatusOfProduct(props, 'Unpublished')}
          >
            Unpublish
          </Button>,
        ]}
      >
        <Title level={5}>
          Are you sure you want to unpublish {props?.name}?
        </Title>
      </Modal>

      <Modal
        visible={showDeleteModal}
        mask={false}
        // onOk={() => changeStatusOfProduct(props,'Published')}
        onCancel={() => setShowDeleteModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => changeStatusOfProduct(props, 'Trash')}
          >
            Move to Trash
          </Button>,
        ]}
      >
        <Title level={5}>
          Are you sure you want to move {props?.name} to trash?
        </Title>
      </Modal>
    </div>
  );
};

export const draftsGridActionItems = (props) => {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userActionNotAllowed = !!(
    role === 'admin' && props?.owner_email_id !== userEmail
  );

  const changeStatusOfProduct = async (props, status) => {
    try {
      if (status === 'Trash') {
        axios
          .patch(
            `${process.env.REACT_APP_CMS_URL}drafts/${props?._id}`,
            {
              status,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              },
            },
          )
          .then((response) => {
            setShowPublishModal(false);
            window.location.reload();
            count += 1;
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              message.error('Something went wrong');
              // return logoutUser()
            } else {
              message.error('Something went wrong.');
            }
          });
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const { Title } = Typography;

  return (
    <div>
      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        disabled={
          (props?.status === 'Published' || userActionNotAllowed) && true
        }
        style={{
          background: '#F4F4F4',
          color:
            props?.status === 'Published' || userActionNotAllowed
              ? 'gray'
              : 'black',
          height: '30px',
          opacity:
            props?.status === 'Published' || userActionNotAllowed ? 0.4 : 1,
          pointerEvents:
            (props?.status === 'Published' || userActionNotAllowed) && 'none',
        }}
      >
        <Link
          // to={{
          //   pathname: `/add?id=${props?._id}`,
          // }}
          to={{
            pathname: `/add`,
              search:  `?id=${props?._id}`
          }}

        >

          <TooltipComponent content="Edit Drafts">
            <MdModeEdit />
          </TooltipComponent>
        </Link>
      </button>

      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        disabled={
          (props?.status === 'Published' || userActionNotAllowed) && true
        }
        onClick={() => setShowDeleteModal(true)}
        style={{
          color:
            props?.status === 'Published' || userActionNotAllowed
              ? 'gray'
              : 'red',
          color: 'red',
          height: '30px',
          opacity:
            props?.status === 'Published' || userActionNotAllowed ? 0.4 : 1,
          marginLeft: '10px',
        }}
      >
        <TooltipComponent content="Move to trash">
          <MdDelete />
        </TooltipComponent>
      </button>

      <Modal
        visible={showDeleteModal}
        mask={false}
        // onOk={() => changeStatusOfProduct(props,'Published')}
        onCancel={() => setShowDeleteModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => changeStatusOfProduct(props, 'Trash')}
          >
            Move to trash
          </Button>,
        ]}
      >
        <Title level={5}>
          Are you sure you want to move {props?.name} to trash?
        </Title>
      </Modal>
    </div>
  );
};

export const trashActionItems = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMoveBackModal, setShowMoveBackModal] = useState(false);

  const userActionNotAllowed = !!(
    role === 'admin' && props?.owner_email_id !== userEmail
  );

  const deleteFromTrash = async () => {
    axios
      .delete(`${process.env.REACT_APP_CMS_URL}trash/${props?._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          message.success('Deleted From Trash');
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log('Error is', error);
        if (error?.response?.status === 401) {
          message.error('Something went wrong');
          // return logoutUser()
        } else {
          message.error('Something went wrong.');
        }
      });
  };

  const addToDraft = async (data) => {
    try {
      axios
        .post(`${process.env.REACT_APP_CMS_URL}drafts`, props, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        })
        .then((response) => {
          // * Delete from Trash
          deleteFromTrash();
          message.success('Reverted back to Draft');
        })
        .catch((error) => {
          console.log(error);
          message.error('Something went wrong. Try Again!');
        });
    } catch (e) {
      console.log('Error is', e);
      if (e?.response?.status === 401) {
        message.error('Something went wrong');
        // return logoutUser()
      } else {
        message.error('Something went wrong.');
      }
    }
  };

  const { Title } = Typography;

  return (
    <div>
      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        onClick={() => setShowMoveBackModal(true)}
        disabled={
          (props?.status === 'Published' || userActionNotAllowed) && true
        }
        style={{
          background: '#F4F4F4',
          height: '30px',
          color:
            props?.status === 'Published' || userActionNotAllowed
              ? 'gray'
              : 'black',
          opacity:
            props?.status === 'Published' || userActionNotAllowed ? 0.4 : 1,
        }}
      >
        <TooltipComponent content="Revert back to Draft">
          <GrRevert />
        </TooltipComponent>
      </button>

      <button
        className="text-white py-1 px-2 capitalize rounded-1xl text-md"
        disabled={
          (props?.status === 'Published' || userActionNotAllowed) && true
        }
        onClick={() => setShowDeleteModal(true)}
        style={{
          color:
            props?.status === 'Published' || userActionNotAllowed
              ? 'gray'
              : 'red',
          color: 'red',
          height: '30px',
          opacity:
            props?.status === 'Published' || userActionNotAllowed ? 0.4 : 1,
          marginLeft: '10px',
        }}
      >
        <TooltipComponent content="Delete Permanently">
          <MdDelete />
        </TooltipComponent>
      </button>

      <Modal
        visible={showMoveBackModal}
        mask={false}
        // onOk={() => changeStatusOfProduct(props,'Published')}
        onCancel={() => setShowMoveBackModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowMoveBackModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => addToDraft()}>
            Revert
          </Button>,
        ]}
      >
        <Title level={5}>
          Are you sure you want to revert {props?.name} back to main table?
        </Title>
      </Modal>
      <Modal
        visible={showDeleteModal}
        mask={false}
        // onOk={() => changeStatusOfProduct(props,'Published')}
        onCancel={() => setShowDeleteModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => deleteFromTrash()}>
            Delete
          </Button>,
        ]}
      >
        <Title level={5}>
          Are you sure you want to delete {props?.name} permanently?
        </Title>
      </Modal>
    </div>
  );
};

const customCheckBox = (props) => (
  <div className="image flex gap-4">
    <input type="checkbox" />
  </div>
);

export const trashGrid = [
  // {
  //   headerText: 'Image',
  //   template: gridOrderImage,
  //   textAlign: 'Center',
  //   width: '120',
  // },
  // { type: "checkbox", width: "50" },

  {
    width: '50',
    template: customCheckBox,
    textAlign: 'Center',
  },
  {
    field: 'name',
    headerText: 'Name',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  {
    field: 'category',
    headerText: 'Category',
    width: '150',
    textAlign: 'Center',
  },
  {
    field: 'sub_category',
    headerText: 'Sub Category',
    format: 'C2',
    textAlign: 'Center',
    editType: 'numericedit',
    width: '150',
  },
  {
    field: 'date_created',
    headerText: 'Date Created',
    template: gridShowDate,
    width: '120',
    textAlign: 'Center',
  },
  {
    field: 'created',
    template: createdByGrid,
    headerText: 'Created by',
    width: '150',
    textAlign: 'Center',
  },
  {
    headerText: 'Status',
    template: gridOrderStatus,
    field: 'status',
    textAlign: 'Center',
    width: '120',
  },
  {
    // field: 'created_by',
    headerText: 'Actions',
    width: '150',
    template: trashActionItems,
    textAlign: 'Center',
  },
];

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'Dashboard',
        icon: <MdDashboard />,
      },
      {
        name: 'Trash',
        icon: <BsTrashFill />,
      },
      {
        name: 'Drafts',
        icon: <MdDrafts />,
      },
    ],
  },
];

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#324CD0',
  },
  {
    name: 'green-theme',
    color: '#324CD0',
  },
  {
    name: 'purple-theme',
    color: '#324CD0',
  },
  {
    name: 'red-theme',
    color: '#324CD0',
  },
  {
    name: 'indigo-theme',
    color: '#324CD0',
  },
  {
    color: '#324CD0',
    name: 'orange-theme',
  },
];

export const userProfileData = [
  {
    icon: <GrUserSettings />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
];

export const dashboardGrid = [
  // {
  //   headerText: 'Image',
  //   template: gridOrderImage,
  //   textAlign: 'Center',
  //   width: '120',
  // },
  // { type: "checkbox", width: "50" },

  {
    width: '50',
    template: customCheckBox,
    textAlign: 'Center',
  },
  {
    field: 'name',
    headerText: 'Name',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  {
    field: 'category',
    headerText: 'Category',
    width: '150',
    textAlign: 'Center',
  },
  {
    field: 'sub_category',
    headerText: 'Sub Category',
    format: 'C2',
    textAlign: 'Center',
    editType: 'numericedit',
    width: '150',
  },
  {
    field: 'date_created',
    headerText: 'Date Created',
    template: gridShowDate,
    width: '120',
    textAlign: 'Center',
  },
  {
    field: 'created',
    template: createdByGrid,
    headerText: 'Created by',
    width: '150',
    textAlign: 'Center',
  },
  {
    headerText: 'Status',
    template: gridOrderStatus,
    field: 'status',
    textAlign: 'Center',
    width: '120',
  },
  {
    // field: 'created_by',
    headerText: 'Actions',
    width: '150',
    template: gridActionItems,
    textAlign: 'Center',
  },
];

export const draftsGrid = [
  // {
  //   headerText: 'Image',
  //   template: gridOrderImage,
  //   textAlign: 'Center',
  //   width: '120',
  // },
  // { type: "checkbox", width: "50" },

  {
    width: '50',
    template: customCheckBox,
    textAlign: 'Center',
  },
  {
    field: 'name',
    headerText: 'Name',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  {
    field: 'category',
    headerText: 'Category',
    width: '150',
    textAlign: 'Center',
  },
  {
    field: 'sub_category',
    headerText: 'Sub Category',
    format: 'C2',
    textAlign: 'Center',
    editType: 'numericedit',
    width: '150',
  },
  {
    field: 'date_created',
    headerText: 'Date Created',
    template: gridShowDate,
    width: '120',
    textAlign: 'Center',
  },
  {
    field: 'created',
    template: createdByGrid,
    headerText: 'Created by',
    width: '150',
    textAlign: 'Center',
  },
  {
    headerText: 'Status',
    template: gridOrderStatusDrafts,
    field: 'status',
    textAlign: 'Center',
    width: '130',
  },
  {
    // field: 'created_by',
    headerText: 'Actions',
    width: '150',
    template: draftsGridActionItems,
    textAlign: 'Center',
  },
];
