import React, { useEffect, useState } from "react";
import { Button, Result } from 'antd';

const Success = ({finalContentData, dataObjectID}) => {

  const addAnother = () => {
    localStorage.removeItem('objectIDForAddProduct')
    window.open('/add', "_self")
  }


  return (
    <>
      <Result
        status="success"
        title="The content has been publised"
        subTitle="Content ID:87468746A7. "
        extra={[
          <Button type="primary" key="console" onClick={() => window.open(`https://atlas.blr1-pp.scoutflo.com/product/${finalContentData?._id ? finalContentData?._id : dataObjectID}`)}>
            Check Live Status
          </Button>,
          <Button key="buy" onClick={addAnother}>Add Another</Button>,
        ]}
      />

    </>
  );
};

export default Success;
