import React, { useEffect, useState } from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page } from '@syncfusion/ej2-react-grids';

import { trashGrid } from '../data/dummy';
import { Header } from '../components';
import axios from 'axios'
import { message } from 'antd';

import { FilterTable } from './FilterTable';
import { logoutUser } from '../commons/Utils';


const Trash = () => {

  const editing = { allowDeleting: false, allowEditing: false };

  const [trashData, setTrashData] = useState([])

  const [searchName, setSearchName] = useState('')
  const token = localStorage.getItem('userToken')

  const role = localStorage.getItem('role')

  const [filterData, setFilterData] = useState({
    date_created: '',
    category: '',
    status: '',
    owner_email_id: role === 'super-admin' ? '' : localStorage.getItem('email'),
    start_date: '',
    end_date: '',
    _id: '',
  })


  const makeApiCall = async (filterDataReceived) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_CMS_URL}trash/?name=${searchName}&owner_email_id=${role == 'admin' ? localStorage.getItem('email') : ''}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: filterDataReceived,
        }).then((res) => {
          if (res.status === 200) {
            return res?.data?.data
          }
        })

      return setTrashData(result)

    }
    catch (e) {
      console.log('Some Error Occurred', e)
      if (e?.response?.status === 401) {
        message.error('User not found')
        return logoutUser()
      }
      else {
        message.error('Something we wrong')
        return null
      }
    }

  }

  useEffect(() => {
    makeApiCall()
    //  setDashboardData(data)
  }, [])

  useEffect(() => {
    if (searchName === '' || searchName.length % 3 === 0) {
      makeApiCall()

    }
    //  setDashboardData(data)
  }, [searchName])

  return (
    <div className="m-2 md:m-2 mt-24 p-2 md:p-10 bg-white rounded-3xl"
      style={{ overflowX: "hidden" }}
    >
      {/* <Header category="Page" title="Trash" /> */}
      <Header category="" title="Trash" />
      <FilterTable searchName={searchName} setSearchName={setSearchName} makeApiCall={makeApiCall} filterData={filterData} setFilterData={setFilterData} />

      <GridComponent
        dataSource={trashData}
        width="auto"
        allowPaging
        allowSorting
        // pageSettings={{ pageCount: 5 }}
        editSettings={editing}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {trashGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />

      </GridComponent>
    </div>
  );
};
export default Trash;
