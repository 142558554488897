export const isURL = (str, domain) => {
  if (domain) {
    if (!str.toString().includes(domain)) {
      return false;
    }
  }

  var pattern = new RegExp(
    "^(https?:\\/\\/)?" // protocol
      + "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" // domain name
      + "((\\d{1,3}\\.){3}\\d{1,3}))" // OR ip (v4) address
      + "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" // port and path
      + "(\\?[;&a-z\\d%_.~+=-]*)?" // query string
      + "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locatorcator

  return !!pattern.test(str);
};

export const isYouTubeURL = (url) => {
  var pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  return pattern.test(url);
};

export const isFinalDataValid = async (finalData, step) => {
  const errorMessages = [];

  const stepOneValidation = () => {
    //Checks if Founding Team Linkedin url is valid url

    if (!finalData?.contact_email_id) {
      errorMessages.push("Please Enter Contact Email ID");
    }

    if (finalData.categories?.length === 0) {
      errorMessages.push("Please Add Categories and Sub-Categories");
    }

    if (finalData.product_website && !isURL(finalData.product_website, "")) {
      errorMessages.push("Please enter a valid product website");
    }

    if (
      finalData.founded_team_linkedin_url
      && !isURL(finalData.founded_team_linkedin_url, "linkedin")
    ) {
      errorMessages.push("Please enter a valid Founding Tean linkedin URL ");
    }

    //Checks if Descriptions exists and is less than 200 characters
    if (!finalData.description) {
      errorMessages.push("Product Description cannot be empty");
    } else {
      if (finalData.description.length > 500) {
        errorMessages.push("Product description must be less than 500 characters");
      }
    }
  };

  const stepTwoValidations = () => {
    //Checks if Twitter url is valid url
    if (
      finalData.social_media_urls.twitter_url
      && !isURL(finalData.social_media_urls.twitter_url, "twitter")
    ) {
      errorMessages.push("Please enter a valid Twitter URL ");
    }

    //Checks if Linkedin url is valid url
    if (
      finalData.social_media_urls.linkedin_url
      && !isURL(finalData.social_media_urls.linkedin_url, "linkedin")
    ) {
      errorMessages.push("Please enter a valid LinkedIn URL ");
    }

    //Checks if Product Hunt url is valid url
    if (
      finalData.social_media_urls.producthunt_url
      && !isURL(finalData.social_media_urls.producthunt_url, "producthunt")
    ) {
      errorMessages.push("Please enter a valid Product Hunt URL ");
    }

    //Checks if Discord url is valid url
    if (
      finalData.social_media_urls.discord_url
      && !isURL(finalData.social_media_urls.discord_url, "discord")
    ) {
      errorMessages.push("Please enter a valid Discord URL ");
    }

    //Checks if Slack url is valid url
    if (
      finalData.social_media_urls.slack_url
      && !isURL(finalData.social_media_urls.slack_url, "slack")
    ) {
      errorMessages.push("Please enter a valid Slack URL ");
    }

    //Checks if Crunchbase url is valid url
    if (
      finalData.social_media_urls.crunchbase_url
      && !isURL(finalData.social_media_urls.crunchbase_url, "crunchbase")
    ) {
      errorMessages.push("Please enter a valid Crunchbase URL ");
    }

    // Checks if Instagram url is valid url
    if (
      finalData.social_media_urls.instagram_url
      && !isURL(finalData.social_media_urls.instagram_url, "instagram")
    ) {
      errorMessages.push("Please enter a valid Instagram URL ");
    }

    //Checks if Gitter url is valid url
    if (
      finalData.social_media_urls.gitter_url
      && !isURL(finalData.social_media_urls.gitter_url, "gitter")
    ) {
      errorMessages.push("Please enter a valid Instagram URL ");
    }

    //Checks if Other url is valid url
    if (finalData.social_media_urls.other_url && !isURL(finalData.social_media_urls.other_url)) {
      errorMessages.push("Please enter a valid Instagram URL ");
    }

    //Checks if minimum 1 hashtag is present
    if (!finalData.social_media_urls.hashtags) {
      errorMessages.push("Enter Minimum 1 hashtags ");
    }

    //Pricing Checks

    //Checks if minimum 1 Pricing Model is present
    var filteredArray = finalData?.pricing_details?.filter((obj) => obj.checked === true);

    if (filteredArray.length === 0) {
      errorMessages.push("Select Minimum 1 Pricing Model ");
    } else {
      //Checks if minimum 1 Pricing Tier is present
      var cycleCheck = filteredArray?.tier?.cycle?.filter((obj) => obj.checked === true);
      if (cycleCheck?.length === 0) {
        errorMessages.push("Select Minimum 1 Pricing Tier ");
      }
    }

    filteredArray.map((data) => {
      data?.tier?.map((tierData) => {
        console.log("Show CTA", tierData?.contact_cta_link);
        if (tierData?.show_contact_cta === true) {
          if (tierData.heading?.length > 50) {
            errorMessages.push(
              `Pricing tier (${data.value}) heading should be less than 50 characters `,
            );
          }

          if (tierData.heading?.description > 200) {
            errorMessages.includes(
              `Pricing tier (${data.value}) description should be less than 200 characters`,
            );
          }

          if (tierData?.contact_cta_link && !isURL(tierData?.contact_cta_link)) {
            errorMessages.includes(`Please Enter valid CTA Link`);
          }
        } else {
          if (tierData.heading?.length > 50) {
            errorMessages.push(
              `Pricing tier (${data.value}) heading should be less than 50 characters `,
            );
          }

          if (tierData.heading?.description > 200) {
            errorMessages.includes(
              `Pricing tier (${data.value}) description should be less than 200 characters`,
            );
          }

          if (!tierData?.currency) {
            errorMessages.push(`Enter minimum one currency for pricing tier ${data.value}`);
          }

          if (!tierData?.rate_monthly) {
            errorMessages.push(`Enter Monthly Rate for pricing tier ${data.value}`);
          }

          if (!tierData?.rate_annualy) {
            errorMessages.push(`Enter Annualy Rate for pricing tier ${data.value}`);
          }

          // if (!tierData?.pricing_value) {
          //   errorMessages.push(
          //     `Enter pricing value for pricing tier ${data.value}`
          //   );
          // }

          // if (!tierData?.pricing_unit) {
          //   errorMessages.push(`Enter pricing unit for pricing tier ${data.value}`);
          // }
        }
      });
    });
  };

  const stepThreeValidations = () => {
    if (finalData.tech_stack_details.length === 0) {
      errorMessages.push("Select minimum 1 tech stack");
    }
  };

  if (step === 1) {
    stepOneValidation();
  } else if (step === 2) {
    stepTwoValidations();
  } else if (step === 3) {
    stepThreeValidations();
  }

  return errorMessages;
};
